import React from 'react';
import './Common.css';
import {Box,} from '@mui/material';

export default class NotVerifiedUserModule extends React.Component {

    constructor(props) {
        super(props);

        let paramString = window.location.href.split('?')[1];

        this.state = {
            email: new URLSearchParams(paramString).get("email"),
            content: new URLSearchParams(paramString).get("content")
        }

    }
    render(){
        let mainMsg;
        if (this.state.content === "emailSent" ) {
            mainMsg = <Box className="form-data" >Verification email has been sent to <b>{ this.state.email }</b><br/></Box>;
        }
        else {
            mainMsg = <Box className="form-data" >Email has not been verified yet <b>{ this.state.email }</b><br/></Box>
        }
        return (
            <Box className="form-body">
                <Box className="form-logo"></Box>
                <Box className="form-name">Verify Your Email</Box>
                {mainMsg}
                <Box className="form-data" >
                    <br/><a href="https://info.msci.com/l/36252/2017-04-05/f8w388">Click here</a> if you did not receive an email.
                </Box>
            </Box>
        )
    }

}

