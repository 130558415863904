import React from 'react';
import './Common.css';
import { Box, } from '@mui/material';
import queryString from 'query-string';


export default class  UserBlockedByApp  extends React.Component{
    constructor(props) {
        super(props);

        let params = new URLSearchParams(window.location.search);

        this.state = {
            user: new URLSearchParams(params).get("user"),
            ip: new URLSearchParams(params).get("ip")
        }

    }

    render(){
        return(
            <Box className="form-body">
                <Box className="form-logo"></Box>
                <Box className="form-name"> Access Denied</Box>
                <Box className="form-data"> User's <b><i>{this.state.user}</i></b> access to application is blocked from the IP <i>{this.state.ip}</i>.<br/>                    
                </Box>
                <Box className="form-data" >
                If you need further assistance, please <a href="https://www.msci.com/contact-us">contact us</a> with the above details.<br/><br></br>
                You may now close this window.
                </Box>
            </Box>
        )
    }

}

