import React from 'react';
import './Common.css';
import { Box, } from '@mui/material';


export default function InsufficientDetails()  {
    return(
        <Box className="form-body">
            <Box className="form-logo" ></Box>
            <Box className="form-name" >Unable To Proceed Further</Box>
            <Box className="form-data" >Looks like you do not have access to this application.<br/>
            Details provided by user are not sufficient to continue login.<br/>
            Please clear cookies and try again.<br/><br/>
            If problem persists, please raise a case with us on <a
                href="https://support.msci.com">support.msci.com</a>.<br/>
            You may now close this window.</Box>
        </Box>
    )
    
}

