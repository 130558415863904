import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import CommonPage from './CommonPage';

const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Routes> {/* The Switch decides which component to show based on the current URL.*/}
          <Route  path='/' element={<CommonPage title="Auth0 Static App" pagetype="blankPage"/>}></Route>
          <Route  path='/user/details' element={<CommonPage title="User Details" pagetype="nonGuest"/>}></Route>
          <Route  path='/user/guest/details' element={<CommonPage title="Guest User Details" pagetype="guest"/>}></Route>
          <Route  path='/user/unverified' element={<CommonPage title="User not Verified" pagetype="unverifiedUser"/>}></Route>
          <Route  path='/user/password/expired' element={<CommonPage title="User Password Expired" pagetype="userPasswordExpired"/>}></Route>
          <Route  path='/user/guest/accessRejected' element={<CommonPage title="Guest User Access Rejected" pagetype="guestAccessRejected"/>}></Route>
          <Route  path='/user/insufficientDetails' element={<CommonPage title="Insufficient Details to proceed Login" pagetype="insufficientDetails"/>}></Route>
          <Route  path='/user/blocked/app' element={<CommonPage title="User access blocked for the application" pagetype="userblockedbyapplication"/>}></Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
);

