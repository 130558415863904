import React from 'react';
import './Common.css';
import { Box } from '@mui/material';


export default function PasswordExpired()  {
    return(
        <Box className="form-body">
            <Box className="form-logo" ></Box>
            <Box className="form-name" >Your password has expired!</Box>
            <Box className="form-data" >We have automatically sent a reset password email to you. Please check your email for further instructions.<br/><br/>
                If you need further assistance, please <a href="https://www.msci.com/contact-us">contact us</a><br/>
                You may now close this window.
            </Box>
        </Box>
    )
    
}
