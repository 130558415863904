export const countryStateMapping = {
    "Andorra": {
        "countryCode": "AD",
        statesList: {
            "Andorra la Vella": "AD-07",
            "Canillo": "AD-02",
            "Encamp": "AD-03",
            "Escaldes-Engordany": "AD-08",
            "La Massana": "AD-04",
            "Ordino": "AD-05",
            "Sant Julia de Loria": "AD-06"
        }
    },
    "United Arab Emirates": {
        "countryCode": "AE",
        statesList: {
            "'Ajman": "AE-AJ",
            "Abu Zaby": "AE-AZ",
            "Al Fujayrah": "AE-FU",
            "Ash Shariqah": "AE-SH",
            "Dubayy": "AE-DU",
            "Ra's al Khaymah": "AE-RK",
            "Umm al Qaywayn": "AE-UQ"
        }
    },
    "Afghanistan": {
        "countryCode": "AF",
        statesList: {
            "Badghis": "AF-BDG",
            "Baghlan": "AF-BGL",
            "Balkh": "AF-BAL",
            "Bamyan": "AF-BAM",
            "Daykundi": "AF-DAY",
            "Farah": "AF-FRA",
            "Faryab": "AF-FYB",
            "Ghazni": "AF-GHA",
            "Ghor": "AF-GHO",
            "Helmand": "AF-HEL",
            "Herat": "AF-HER",
            "Jowzjan": "AF-JOW",
            "Kabul": "AF-KAB",
            "Kandahar": "AF-KAN",
            "Khost": "AF-KHO",
            "Kunduz": "AF-KDZ",
            "Laghman": "AF-LAG",
            "Logar": "AF-LOG",
            "Nangarhar": "AF-NAN",
            "Nimroz": "AF-NIM",
            "Paktika": "AF-PKA",
            "Paktiya": "AF-PIA",
            "Panjshayr": "AF-PAN",
            "Parwan": "AF-PAR",
            "Samangan": "AF-SAM",
            "Sar-e Pul": "AF-SAR",
            "Takhar": "AF-TAK",
            "Uruzgan": "AF-URU"
        }
    },
    "Antigua and Barbuda": {
        "countryCode": "AG",
        statesList: {
            "Barbuda": "AG-10",
            "Redonda": "AG-11",
            "Saint George": "AG-03",
            "Saint John": "AG-04",
            "Saint Mary": "AG-05",
            "Saint Peter": "AG-07",
            "Saint Philip": "AG-08"
        }
    },
    "Anguilla": {
        "countryCode": "AI",
        statesList: {}
    },
    "Albania": {
        "countryCode": "AL",
        statesList: {
            "Berat": "AL-01",
            "Diber": "AL-09",
            "Durres": "AL-02",
            "Elbasan": "AL-03",
            "Fier": "AL-04",
            "Gjirokaster": "AL-05",
            "Korce": "AL-06",
            "Kukes": "AL-07",
            "Lezhe": "AL-08",
            "Shkoder": "AL-10",
            "Tirane": "AL-11",
            "Vlore": "AL-12"
        }
    },
    "Armenia": {
        "countryCode": "AM",
        statesList: {
            "Aragacotn": "AM-AG",
            "Ararat": "AM-AR",
            "Armavir": "AM-AV",
            "Erevan": "AM-ER",
            "Gegark'unik'": "AM-GR",
            "Kotayk'": "AM-KT",
            "Lori": "AM-LO",
            "Sirak": "AM-SH",
            "Syunik'": "AM-SU",
            "Tavus": "AM-TV",
            "Vayoc Jor": "AM-VD"
        }
    },
    "Angola": {
        "countryCode": "AO",
        statesList: {
            "Bengo": "AO-BGO",
            "Benguela": "AO-BGU",
            "Bie": "AO-BIE",
            "Cabinda": "AO-CAB",
            "Cuando Cubango": "AO-CCU",
            "Cuanza-Norte": "AO-CNO",
            "Cuanza-Sul": "AO-CUS",
            "Cunene": "AO-CNN",
            "Huambo": "AO-HUA",
            "Huila": "AO-HUI",
            "Luanda": "AO-LUA",
            "Lunda-Norte": "AO-LNO",
            "Lunda-Sul": "AO-LSU",
            "Malange": "AO-MAL",
            "Moxico": "AO-MOX",
            "Namibe": "AO-NAM",
            "Uige": "AO-UIG",
            "Zaire": "AO-ZAI"
        }
    },
    "Antarctica": {
        "countryCode": "AQ",
        statesList: {}
    },
    "Argentina": {
        "countryCode": "AR",
        statesList: {
            "Buenos Aires": "AR-B",
            "Catamarca": "AR-K",
            "Chaco": "AR-H",
            "Chubut": "AR-U",
            "Ciudad Autonoma de Buenos Aires": "AR-C",
            "Cordoba": "AR-X",
            "Corrientes": "AR-W",
            "Entre Rios": "AR-E",
            "Formosa": "AR-P",
            "Jujuy": "AR-Y",
            "La Pampa": "AR-L",
            "La Rioja": "AR-F",
            "Mendoza": "AR-M",
            "Misiones": "AR-N",
            "Neuquen": "AR-Q",
            "Rio Negro": "AR-R",
            "Salta": "AR-A",
            "San Juan": "AR-J",
            "San Luis": "AR-D",
            "Santa Cruz": "AR-Z",
            "Santa Fe": "AR-S",
            "Santiago del Estero": "AR-G",
            "Tierra del Fuego": "AR-V",
            "Tucuman": "AR-T"
        }
    },
    "American Samoa": {
        "countryCode": "AS",
        statesList: {}
    },
    "Austria": {
        "countryCode": "AT",
        statesList: {
            "Burgenland": "AT-1",
            "Karnten": "AT-2",
            "Niederosterreich": "AT-3",
            "Oberosterreich": "AT-4",
            "Salzburg": "AT-5",
            "Steiermark": "AT-6",
            "Tirol": "AT-7",
            "Vorarlberg": "AT-8",
            "Wien": "AT-9"
        }
    },
    "Australia": {
        "countryCode": "AU",
        statesList: {
            "Australian Capital Territory": "AU-ACT",
            "New South Wales": "AU-NSW",
            "Northern Territory": "AU-NT",
            "Queensland": "AU-QLD",
            "South Australia": "AU-SA",
            "Tasmania": "AU-TAS",
            "Victoria": "AU-VIC",
            "Western Australia": "AU-WA"
        }
    },
    "Aruba": {
        "countryCode": "AW",
        statesList: {}
    },
    "Aland Islands": {
        "countryCode": "AX",
        statesList: {}
    },
    "Azerbaijan": {
        "countryCode": "AZ",
        statesList: {
            "Abseron": "AZ-ABS",
            "Agcabadi": "AZ-AGC",
            "Agdas": "AZ-AGS",
            "Agstafa": "AZ-AGA",
            "Agsu": "AZ-AGU",
            "Astara": "AZ-AST",
            "Baki": "AZ-BA",
            "Balakan": "AZ-BAL",
            "Barda": "AZ-BAR",
            "Beylaqan": "AZ-BEY",
            "Daskasan": "AZ-DAS",
            "Fuzuli": "AZ-FUZ",
            "Gadabay": "AZ-GAD",
            "Ganca": "AZ-GA",
            "Goycay": "AZ-GOY",
            "Goygol": "AZ-GYG",
            "Imisli": "AZ-IMI",
            "Ismayilli": "AZ-ISM",
            "Kurdamir": "AZ-KUR",
            "Lankaran": "AZ-LA",
            "Masalli": "AZ-MAS",
            "Mingacevir": "AZ-MI",
            "Naftalan": "AZ-NA",
            "Naxcivan": "AZ-NX",
            "Neftcala": "AZ-NEF",
            "Oguz": "AZ-OGU",
            "Qabala": "AZ-QAB",
            "Qax": "AZ-QAX",
            "Qazax": "AZ-QAZ",
            "Quba": "AZ-QBA",
            "Qusar": "AZ-QUS",
            "Saatli": "AZ-SAT",
            "Sabirabad": "AZ-SAB",
            "Saki": "AZ-SAK",
            "Salyan": "AZ-SAL",
            "Samaxi": "AZ-SMI",
            "Samkir": "AZ-SKR",
            "Samux": "AZ-SMX",
            "Sirvan": "AZ-SR",
            "Siyazan": "AZ-SIY",
            "Sumqayit": "AZ-SM",
            "Tartar": "AZ-TAR",
            "Tovuz": "AZ-TOV",
            "Ucar": "AZ-UCA",
            "Xacmaz": "AZ-XAC",
            "Xizi": "AZ-XIZ",
            "Yardimli": "AZ-YAR",
            "Yevlax": "AZ-YEV",
            "Zaqatala": "AZ-ZAQ",
            "Zardab": "AZ-ZAR"
        }
    },
    "Bosnia and Herzegovina": {
        "countryCode": "BA",
        statesList: {
            "Brcko distrikt": "BA-BRC",
            "Federacija Bosne i Hercegovine": "BA-BIH",
            "Republika Srpska": "BA-SRP"
        }
    },
    "Barbados": {
        "countryCode": "BB",
        statesList: {
            "Christ Church": "BB-01",
            "Saint Andrew": "BB-02",
            "Saint George": "BB-03",
            "Saint James": "BB-04",
            "Saint John": "BB-05",
            "Saint Lucy": "BB-07",
            "Saint Michael": "BB-08",
            "Saint Peter": "BB-09",
            "Saint Philip": "BB-10",
            "Saint Thomas": "BB-11"
        }
    },
    "Bangladesh": {
        "countryCode": "BD",
        statesList: {
            "Barishal": "BD-A",
            "Chattogram": "BD-B",
            "Dhaka": "BD-C",
            "Khulna": "BD-D",
            "Rajshahi": "BD-E",
            "Rangpur": "BD-F",
            "Sylhet": "BD-G"
        }
    },
    "Belgium": {
        "countryCode": "BE",
        statesList: {
            "Antwerpen": "BE-VAN",
            "Brabant wallon": "BE-WBR",
            "Brussels Hoofdstedelijk Gewest": "BE-BRU",
            "Hainaut": "BE-WHT",
            "Liege": "BE-WLG",
            "Limburg": "BE-VLI",
            "Luxembourg": "BE-WLX",
            "Namur": "BE-WNA",
            "Oost-Vlaanderen": "BE-VOV",
            "Vlaams-Brabant": "BE-VBR",
            "West-Vlaanderen": "BE-VWV"
        }
    },
    "Burkina Faso": {
        "countryCode": "BF",
        statesList: {
            "Bale": "BF-BAL",
            "Bam": "BF-BAM",
            "Bazega": "BF-BAZ",
            "Bougouriba": "BF-BGR",
            "Boulgou": "BF-BLG",
            "Boulkiemde": "BF-BLK",
            "Comoe": "BF-COM",
            "Ganzourgou": "BF-GAN",
            "Gnagna": "BF-GNA",
            "Gourma": "BF-GOU",
            "Houet": "BF-HOU",
            "Kadiogo": "BF-KAD",
            "Kenedougou": "BF-KEN",
            "Kompienga": "BF-KMP",
            "Kossi": "BF-KOS",
            "Kouritenga": "BF-KOT",
            "Kourweogo": "BF-KOW",
            "Leraba": "BF-LER",
            "Loroum": "BF-LOR",
            "Mouhoun": "BF-MOU",
            "Nahouri": "BF-NAO",
            "Namentenga": "BF-NAM",
            "Nayala": "BF-NAY",
            "Oubritenga": "BF-OUB",
            "Oudalan": "BF-OUD",
            "Passore": "BF-PAS",
            "Sanmatenga": "BF-SMT",
            "Seno": "BF-SEN",
            "Sissili": "BF-SIS",
            "Soum": "BF-SOM",
            "Sourou": "BF-SOR",
            "Tapoa": "BF-TAP",
            "Tuy": "BF-TUI",
            "Yatenga": "BF-YAT",
            "Ziro": "BF-ZIR",
            "Zondoma": "BF-ZON",
            "Zoundweogo": "BF-ZOU"
        }
    },
    "Bulgaria": {
        "countryCode": "BG",
        statesList: {
            "Blagoevgrad": "BG-01",
            "Burgas": "BG-02",
            "Dobrich": "BG-08",
            "Gabrovo": "BG-07",
            "Haskovo": "BG-26",
            "Kardzhali": "BG-09",
            "Kyustendil": "BG-10",
            "Lovech": "BG-11",
            "Montana": "BG-12",
            "Pazardzhik": "BG-13",
            "Pernik": "BG-14",
            "Pleven": "BG-15",
            "Plovdiv": "BG-16",
            "Razgrad": "BG-17",
            "Ruse": "BG-18",
            "Shumen": "BG-27",
            "Silistra": "BG-19",
            "Sliven": "BG-20",
            "Smolyan": "BG-21",
            "Sofia": "BG-23",
            "Sofia (stolitsa)": "BG-22",
            "Stara Zagora": "BG-24",
            "Targovishte": "BG-25",
            "Varna": "BG-03",
            "Veliko Tarnovo": "BG-04",
            "Vidin": "BG-05",
            "Vratsa": "BG-06",
            "Yambol": "BG-28"
        }
    },
    "Bahrain": {
        "countryCode": "BH",
        statesList: {
            "Al 'Asimah": "BH-13",
            "Al Janubiyah": "BH-14",
            "Al Muharraq": "BH-15",
            "Ash Shamaliyah": "BH-17"
        }
    },
    "Burundi": {
        "countryCode": "BI",
        statesList: {
            "Bujumbura Mairie": "BI-BM",
            "Bururi": "BI-BR",
            "Cibitoke": "BI-CI",
            "Gitega": "BI-GI",
            "Kirundo": "BI-KI",
            "Mwaro": "BI-MW",
            "Ngozi": "BI-NG",
            "Rumonge": "BI-RM",
            "Rutana": "BI-RT",
            "Ruyigi": "BI-RY"
        }
    },
    "Benin": {
        "countryCode": "BJ",
        statesList: {
            "Atlantique": "BJ-AQ",
            "Borgou": "BJ-BO",
            "Collines": "BJ-CO",
            "Littoral": "BJ-LI",
            "Mono": "BJ-MO",
            "Oueme": "BJ-OU",
            "Plateau": "BJ-PL",
            "Zou": "BJ-ZO"
        }
    },
    "Saint Barthelemy": {
        "countryCode": "BL",
        statesList: {}
    },
    "Bermuda": {
        "countryCode": "BM",
        statesList: {}
    },
    "Brunei Darussalam": {
        "countryCode": "BN",
        statesList: {
            "Belait": "BN-BE",
            "Brunei-Muara": "BN-BM",
            "Temburong": "BN-TE",
            "Tutong": "BN-TU"
        }
    },
    "Bolivia (Plurinational State of)": {
        "countryCode": "BO",
        statesList: {
            "Chuquisaca": "BO-H",
            "Cochabamba": "BO-C",
            "El Beni": "BO-B",
            "La Paz": "BO-L",
            "Oruro": "BO-O",
            "Pando": "BO-N",
            "Potosi": "BO-P",
            "Santa Cruz": "BO-S",
            "Tarija": "BO-T"
        }
    },
    "Bonaire": {
        "countryCode": "BQ",
        statesList: {
            "Bonaire": "BQ-BO",
            "Saba": "BQ-SA",
            "Sint Eustatius": "BQ-SE"
        }
    },
    "Brazil": {
        "countryCode": "BR",
        statesList: {
            "Acre": "BR-AC",
            "Alagoas": "BR-AL",
            "Amapa": "BR-AP",
            "Amazonas": "BR-AM",
            "Bahia": "BR-BA",
            "Ceara": "BR-CE",
            "Distrito Federal": "BR-DF",
            "Espirito Santo": "BR-ES",
            "Goias": "BR-GO",
            "Maranhao": "BR-MA",
            "Mato Grosso": "BR-MT",
            "Mato Grosso do Sul": "BR-MS",
            "Minas Gerais": "BR-MG",
            "Para": "BR-PA",
            "Paraiba": "BR-PB",
            "Parana": "BR-PR",
            "Pernambuco": "BR-PE",
            "Piaui": "BR-PI",
            "Rio Grande do Norte": "BR-RN",
            "Rio Grande do Sul": "BR-RS",
            "Rio de Janeiro": "BR-RJ",
            "Rondonia": "BR-RO",
            "Roraima": "BR-RR",
            "Santa Catarina": "BR-SC",
            "Sao Paulo": "BR-SP",
            "Sergipe": "BR-SE",
            "Tocantins": "BR-TO"
        }
    },
    "Bahamas": {
        "countryCode": "BS",
        statesList: {
            "Black Point": "BS-BP",
            "Central Abaco": "BS-CO",
            "City of Freeport": "BS-FP",
            "East Grand Bahama": "BS-EG",
            "Harbour Island": "BS-HI",
            "Long Island": "BS-LI",
            "New Providence": "BS-NP",
            "North Andros": "BS-NS",
            "North Eleuthera": "BS-NE",
            "San Salvador": "BS-SS",
            "South Eleuthera": "BS-SE",
            "West Grand Bahama": "BS-WG"
        }
    },
    "Bhutan": {
        "countryCode": "BT",
        statesList: {
            "Bumthang": "BT-33",
            "Chhukha": "BT-12",
            "Dagana": "BT-22",
            "Gasa": "BT-GA",
            "Lhuentse": "BT-44",
            "Monggar": "BT-42",
            "Paro": "BT-11",
            "Pema Gatshel": "BT-43",
            "Punakha": "BT-23",
            "Samdrup Jongkhar": "BT-45",
            "Samtse": "BT-14",
            "Sarpang": "BT-31",
            "Thimphu": "BT-15",
            "Trashigang": "BT-41",
            "Trongsa": "BT-32",
            "Tsirang": "BT-21",
            "Wangdue Phodrang": "BT-24"
        }
    },
    "Bouvet Island": {
        "countryCode": "BV",
        statesList: {}
    },
    "Botswana": {
        "countryCode": "BW",
        statesList: {
            "Central": "BW-CE",
            "Chobe": "BW-CH",
            "Ghanzi": "BW-GH",
            "Kgalagadi": "BW-KG",
            "Kgatleng": "BW-KL",
            "Kweneng": "BW-KW",
            "North East": "BW-NE",
            "North West": "BW-NW",
            "South East": "BW-SE",
            "Southern": "BW-SO"
        }
    },
    "Belarus": {
        "countryCode": "BY",
        statesList: {
            "Brestskaya voblasts'": "BY-BR",
            "Homyel'skaya voblasts'": "BY-HO",
            "Horad Minsk": "BY-HM",
            "Hrodzyenskaya voblasts'": "BY-HR",
            "Mahilyowskaya voblasts'": "BY-MA",
            "Minskaya voblasts'": "BY-MI",
            "Vitsyebskaya voblasts'": "BY-VI"
        }
    },
    "Belize": {
        "countryCode": "BZ",
        statesList: {
            "Belize": "BZ-BZ",
            "Cayo": "BZ-CY",
            "Corozal": "BZ-CZL",
            "Orange Walk": "BZ-OW",
            "Stann Creek": "BZ-SC",
            "Toledo": "BZ-TOL"
        }
    },
    "Canada": {
        "countryCode": "CA",
        statesList: {
            "Alberta": "CA-AB",
            "British Columbia": "CA-BC",
            "Manitoba": "CA-MB",
            "New Brunswick": "CA-NB",
            "Newfoundland and Labrador": "CA-NL",
            "Northwest Territories": "CA-NT",
            "Nova Scotia": "CA-NS",
            "Nunavut": "CA-NU",
            "Ontario": "CA-ON",
            "Prince Edward Island": "CA-PE",
            "Quebec": "CA-QC",
            "Saskatchewan": "CA-SK",
            "Yukon": "CA-YT"
        }
    },
    "Cocos (Keeling) Islands": {
        "countryCode": "CC",
        statesList: {}
    },
    "Congo (Democratic Republic of the)": {
        "countryCode": "CD",
        statesList: {
            "Equateur": "CD-EQ",
            "Haut-Katanga": "CD-HK",
            "Ituri": "CD-IT",
            "Kasai": "CD-KS",
            "Kasai Central": "CD-KC",
            "Kasai Oriental": "CD-KE",
            "Kinshasa": "CD-KN",
            "Kwango": "CD-KG",
            "Lualaba": "CD-LU",
            "Maniema": "CD-MA",
            "Nord-Kivu": "CD-NK",
            "Sankuru": "CD-SA",
            "Sud-Kivu": "CD-SK",
            "Tanganyika": "CD-TA",
            "Tshopo": "CD-TO"
        }
    },
    "Central African Republic": {
        "countryCode": "CF",
        statesList: {
            "Bamingui-Bangoran": "CF-BB",
            "Bangui": "CF-BGF",
            "Gribingui": "CF-KB",
            "Kemo-Gribingui": "CF-KG",
            "Mambere-Kadei": "CF-HS",
            "Nana-Mambere": "CF-NM",
            "Ouaka": "CF-UK",
            "Ouham": "CF-AC",
            "Ouham-Pende": "CF-OP"
        }
    },
    "Congo": {
        "countryCode": "CG",
        statesList: {
            "Bouenza": "CG-11",
            "Brazzaville": "CG-BZV",
            "Cuvette": "CG-8",
            "Niari": "CG-9",
            "Pointe-Noire": "CG-16",
            "Sangha": "CG-13"
        }
    },
    "Switzerland": {
        "countryCode": "CH",
        statesList: {
            "Aargau": "CH-AG",
            "Appenzell Ausserrhoden": "CH-AR",
            "Appenzell Innerrhoden": "CH-AI",
            "Basel-Landschaft": "CH-BL",
            "Basel-Stadt": "CH-BS",
            "Bern": "CH-BE",
            "Fribourg": "CH-FR",
            "Geneve": "CH-GE",
            "Glarus": "CH-GL",
            "Graubunden": "CH-GR",
            "Jura": "CH-JU",
            "Luzern": "CH-LU",
            "Neuchatel": "CH-NE",
            "Nidwalden": "CH-NW",
            "Obwalden": "CH-OW",
            "Sankt Gallen": "CH-SG",
            "Schaffhausen": "CH-SH",
            "Schwyz": "CH-SZ",
            "Solothurn": "CH-SO",
            "Thurgau": "CH-TG",
            "Ticino": "CH-TI",
            "Uri": "CH-UR",
            "Valais": "CH-VS",
            "Vaud": "CH-VD",
            "Zug": "CH-ZG",
            "Zurich": "CH-ZH"
        }
    },
    "Cote d'Ivoire": {
        "countryCode": "CI",
        statesList: {
            "Abidjan": "CI-AB",
            "Bas-Sassandra": "CI-BS",
            "Comoe": "CI-CM",
            "Denguele": "CI-DN",
            "Goh-Djiboua": "CI-GD",
            "Lacs": "CI-LC",
            "Lagunes": "CI-LG",
            "Montagnes": "CI-MG",
            "Sassandra-Marahoue": "CI-SM",
            "Savanes": "CI-SV",
            "Vallee du Bandama": "CI-VB",
            "Woroba": "CI-WR",
            "Yamoussoukro": "CI-YM",
            "Zanzan": "CI-ZZ"
        }
    },
    "Cook Islands": {
        "countryCode": "CK",
        statesList: {}
    },
    "Chile": {
        "countryCode": "CL",
        statesList: {
            "Aisen del General Carlos Ibanez del Campo": "CL-AI",
            "Antofagasta": "CL-AN",
            "Arica y Parinacota": "CL-AP",
            "Atacama": "CL-AT",
            "Biobio": "CL-BI",
            "Coquimbo": "CL-CO",
            "La Araucania": "CL-AR",
            "Libertador General Bernardo O'Higgins": "CL-LI",
            "Los Lagos": "CL-LL",
            "Los Rios": "CL-LR",
            "Magallanes": "CL-MA",
            "Maule": "CL-ML",
            "Nuble": "CL-NB",
            "Region Metropolitana de Santiago": "CL-RM",
            "Tarapaca": "CL-TA",
            "Valparaiso": "CL-VS"
        }
    },
    "Cameroon": {
        "countryCode": "CM",
        statesList: {
            "Adamaoua": "CM-AD",
            "Centre": "CM-CE",
            "Est": "CM-ES",
            "Extreme-Nord": "CM-EN",
            "Littoral": "CM-LT",
            "Nord": "CM-NO",
            "Nord-Ouest": "CM-NW",
            "Ouest": "CM-OU",
            "Sud": "CM-SU",
            "Sud-Ouest": "CM-SW"
        }
    },
    "China": {
        "countryCode": "CN",
        statesList: {
            "Anhui": "CN-AH",
            "Beijing": "CN-BJ",
            "Chongqing": "CN-CQ",
            "Fujian": "CN-FJ",
            "Gansu": "CN-GS",
            "Guangdong": "CN-GD",
            "Guangxi Zhuangzu": "CN-GX",
            "Guizhou": "CN-GZ",
            "Hainan": "CN-HI",
            "Hebei": "CN-HE",
            "Heilongjiang": "CN-HL",
            "Henan": "CN-HA",
            "Hubei": "CN-HB",
            "Hunan": "CN-HN",
            "Jiangsu": "CN-JS",
            "Jiangxi": "CN-JX",
            "Jilin": "CN-JL",
            "Liaoning": "CN-LN",
            "Nei Mongol": "CN-NM",
            "Ningxia Huizu": "CN-NX",
            "Qinghai": "CN-QH",
            "Shaanxi": "CN-SN",
            "Shandong": "CN-SD",
            "Shanghai": "CN-SH",
            "Shanxi": "CN-SX",
            "Sichuan": "CN-SC",
            "Tianjin": "CN-TJ",
            "Xinjiang Uygur": "CN-XJ",
            "Xizang": "CN-XZ",
            "Yunnan": "CN-YN",
            "Zhejiang": "CN-ZJ"
        }
    },
    "Colombia": {
        "countryCode": "CO",
        statesList: {
            "Amazonas": "CO-AMA",
            "Antioquia": "CO-ANT",
            "Arauca": "CO-ARA",
            "Atlantico": "CO-ATL",
            "Bolivar": "CO-BOL",
            "Boyaca": "CO-BOY",
            "Caldas": "CO-CAL",
            "Caqueta": "CO-CAQ",
            "Casanare": "CO-CAS",
            "Cauca": "CO-CAU",
            "Cesar": "CO-CES",
            "Choco": "CO-CHO",
            "Cordoba": "CO-COR",
            "Cundinamarca": "CO-CUN",
            "Distrito Capital de Bogota": "CO-DC",
            "Guainia": "CO-GUA",
            "Guaviare": "CO-GUV",
            "Huila": "CO-HUI",
            "La Guajira": "CO-LAG",
            "Magdalena": "CO-MAG",
            "Meta": "CO-MET",
            "Narino": "CO-NAR",
            "Norte de Santander": "CO-NSA",
            "Putumayo": "CO-PUT",
            "Quindio": "CO-QUI",
            "Risaralda": "CO-RIS",
            "San Andres, Providencia y Santa Catalina": "CO-SAP",
            "Santander": "CO-SAN",
            "Sucre": "CO-SUC",
            "Tolima": "CO-TOL",
            "Valle del Cauca": "CO-VAC",
            "Vichada": "CO-VID"
        }
    },
    "Costa Rica": {
        "countryCode": "CR",
        statesList: {
            "Alajuela": "CR-A",
            "Cartago": "CR-C",
            "Guanacaste": "CR-G",
            "Heredia": "CR-H",
            "Limon": "CR-L",
            "Puntarenas": "CR-P",
            "San Jose": "CR-SJ"
        }
    },
    "Cuba": {
        "countryCode": "CU",
        statesList: {
            "Artemisa": "CU-15",
            "Camaguey": "CU-09",
            "Ciego de Avila": "CU-08",
            "Cienfuegos": "CU-06",
            "Granma": "CU-12",
            "Guantanamo": "CU-14",
            "Holguin": "CU-11",
            "Isla de la Juventud": "CU-99",
            "La Habana": "CU-03",
            "Las Tunas": "CU-10",
            "Matanzas": "CU-04",
            "Mayabeque": "CU-16",
            "Pinar del Rio": "CU-01",
            "Sancti Spiritus": "CU-07",
            "Santiago de Cuba": "CU-13",
            "Villa Clara": "CU-05"
        }
    },
    "Cabo Verde": {
        "countryCode": "CV",
        statesList: {
            "Boa Vista": "CV-BV",
            "Brava": "CV-BR",
            "Mosteiros": "CV-MO",
            "Porto Novo": "CV-PN",
            "Praia": "CV-PR",
            "Ribeira Grande de Santiago": "CV-RS",
            "Sal": "CV-SL",
            "Sao Domingos": "CV-SD",
            "Sao Vicente": "CV-SV",
            "Tarrafal": "CV-TA"
        }
    },
    "Curaçao": {
        "countryCode": "CW",
        statesList: {}
    },
    "Christmas Island": {
        "countryCode": "CX",
        statesList: {}
    },
    "Cyprus": {
        "countryCode": "CY",
        statesList: {
            "Ammochostos": "CY-04",
            "Keryneia": "CY-06",
            "Larnaka": "CY-03",
            "Lefkosia": "CY-01",
            "Lemesos": "CY-02",
            "Pafos": "CY-05"
        }
    },
    "Czechia": {
        "countryCode": "CZ",
        statesList: {
            "Jihocesky kraj": "CZ-31",
            "Jihomoravsky kraj": "CZ-64",
            "Karlovarsky kraj": "CZ-41",
            "Kraj Vysocina": "CZ-63",
            "Kralovehradecky kraj": "CZ-52",
            "Liberecky kraj": "CZ-51",
            "Moravskoslezsky kraj": "CZ-80",
            "Olomoucky kraj": "CZ-71",
            "Pardubicky kraj": "CZ-53",
            "Plzensky kraj": "CZ-32",
            "Praha, Hlavni mesto": "CZ-10",
            "Stredocesky kraj": "CZ-20",
            "Ustecky kraj": "CZ-42",
            "Zlinsky kraj": "CZ-72"
        }
    },
    "Germany": {
        "countryCode": "DE",
        statesList: {
            "Baden-Wurttemberg": "DE-BW",
            "Bayern": "DE-BY",
            "Berlin": "DE-BE",
            "Brandenburg": "DE-BB",
            "Bremen": "DE-HB",
            "Hamburg": "DE-HH",
            "Hessen": "DE-HE",
            "Mecklenburg-Vorpommern": "DE-MV",
            "Niedersachsen": "DE-NI",
            "Nordrhein-Westfalen": "DE-NW",
            "Rheinland-Pfalz": "DE-RP",
            "Saarland": "DE-SL",
            "Sachsen": "DE-SN",
            "Sachsen-Anhalt": "DE-ST",
            "Schleswig-Holstein": "DE-SH",
            "Thuringen": "DE-TH"
        }
    },
    "Djibouti": {
        "countryCode": "DJ",
        statesList: {
            "Arta": "DJ-AR",
            "Dikhil": "DJ-DI",
            "Djibouti": "DJ-DJ"
        }
    },
    "Denmark": {
        "countryCode": "DK",
        statesList: {
            "Hovedstaden": "DK-84",
            "Midtjylland": "DK-82",
            "Nordjylland": "DK-81",
            "Sjaelland": "DK-85",
            "Syddanmark": "DK-83"
        }
    },
    "Dominica": {
        "countryCode": "DM",
        statesList: {
            "Saint Andrew": "DM-02",
            "Saint George": "DM-04",
            "Saint John": "DM-05",
            "Saint Joseph": "DM-06",
            "Saint Luke": "DM-07",
            "Saint Patrick": "DM-09",
            "Saint Paul": "DM-10"
        }
    },
    "Dominican Republic": {
        "countryCode": "DO",
        statesList: {
            "Azua": "DO-02",
            "Baoruco": "DO-03",
            "Barahona": "DO-04",
            "Dajabon": "DO-05",
            "Distrito Nacional (Santo Domingo)": "DO-01",
            "Duarte": "DO-06",
            "El Seibo": "DO-08",
            "Elias Pina": "DO-07",
            "Espaillat": "DO-09",
            "Hato Mayor": "DO-30",
            "Hermanas Mirabal": "DO-19",
            "Independencia": "DO-10",
            "La Altagracia": "DO-11",
            "La Romana": "DO-12",
            "La Vega": "DO-13",
            "Maria Trinidad Sanchez": "DO-14",
            "Monsenor Nouel": "DO-28",
            "Monte Cristi": "DO-15",
            "Monte Plata": "DO-29",
            "Pedernales": "DO-16",
            "Peravia": "DO-17",
            "Puerto Plata": "DO-18",
            "Samana": "DO-20",
            "San Cristobal": "DO-21",
            "San Jose de Ocoa": "DO-31",
            "San Juan": "DO-22",
            "San Pedro de Macoris": "DO-23",
            "Sanchez Ramirez": "DO-24",
            "Santiago": "DO-25",
            "Santiago Rodriguez": "DO-26",
            "Valverde": "DO-27"
        }
    },
    "Algeria": {
        "countryCode": "DZ",
        statesList: {
            "Adrar": "DZ-01",
            "Ain Defla": "DZ-44",
            "Ain Temouchent": "DZ-46",
            "Alger": "DZ-16",
            "Annaba": "DZ-23",
            "Batna": "DZ-05",
            "Bechar": "DZ-08",
            "Bejaia": "DZ-06",
            "Biskra": "DZ-07",
            "Blida": "DZ-09",
            "Bordj Bou Arreridj": "DZ-34",
            "Bouira": "DZ-10",
            "Boumerdes": "DZ-35",
            "Chlef": "DZ-02",
            "Constantine": "DZ-25",
            "Djanet": "DZ-56",
            "Djelfa": "DZ-17",
            "El Bayadh": "DZ-32",
            "El Meghaier": "DZ-57",
            "El Oued": "DZ-39",
            "El Tarf": "DZ-36",
            "Ghardaia": "DZ-47",
            "Guelma": "DZ-24",
            "Illizi": "DZ-33",
            "In Salah": "DZ-53",
            "Jijel": "DZ-18",
            "Khenchela": "DZ-40",
            "Laghouat": "DZ-03",
            "M'sila": "DZ-28",
            "Mascara": "DZ-29",
            "Medea": "DZ-26",
            "Mila": "DZ-43",
            "Mostaganem": "DZ-27",
            "Naama": "DZ-45",
            "Oran": "DZ-31",
            "Ouargla": "DZ-30",
            "Ouled Djellal": "DZ-51",
            "Oum el Bouaghi": "DZ-04",
            "Relizane": "DZ-48",
            "Saida": "DZ-20",
            "Setif": "DZ-19",
            "Sidi Bel Abbes": "DZ-22",
            "Skikda": "DZ-21",
            "Souk Ahras": "DZ-41",
            "Tamanrasset": "DZ-11",
            "Tebessa": "DZ-12",
            "Tiaret": "DZ-14",
            "Timimoun": "DZ-49",
            "Tindouf": "DZ-37",
            "Tipaza": "DZ-42",
            "Tissemsilt": "DZ-38",
            "Tizi Ouzou": "DZ-15",
            "Tlemcen": "DZ-13",
            "Touggourt": "DZ-55"
        }
    },
    "Ecuador": {
        "countryCode": "EC",
        statesList: {
            "Azuay": "EC-A",
            "Bolivar": "EC-B",
            "Canar": "EC-F",
            "Carchi": "EC-C",
            "Chimborazo": "EC-H",
            "Cotopaxi": "EC-X",
            "El Oro": "EC-O",
            "Esmeraldas": "EC-E",
            "Galapagos": "EC-W",
            "Guayas": "EC-G",
            "Imbabura": "EC-I",
            "Loja": "EC-L",
            "Los Rios": "EC-R",
            "Manabi": "EC-M",
            "Morona Santiago": "EC-S",
            "Napo": "EC-N",
            "Orellana": "EC-D",
            "Pastaza": "EC-Y",
            "Pichincha": "EC-P",
            "Santa Elena": "EC-SE",
            "Santo Domingo de los Tsachilas": "EC-SD",
            "Sucumbios": "EC-U",
            "Tungurahua": "EC-T",
            "Zamora Chinchipe": "EC-Z"
        }
    },
    "Estonia": {
        "countryCode": "EE",
        statesList: {
            "Harjumaa": "EE-37",
            "Hiiumaa": "EE-39",
            "Ida-Virumaa": "EE-45",
            "Jarvamaa": "EE-52",
            "Jogevamaa": "EE-50",
            "Laane-Virumaa": "EE-60",
            "Laanemaa": "EE-56",
            "Parnumaa": "EE-68",
            "Polvamaa": "EE-64",
            "Raplamaa": "EE-71",
            "Saaremaa": "EE-74",
            "Tartumaa": "EE-79",
            "Valgamaa": "EE-81",
            "Viljandimaa": "EE-84",
            "Vorumaa": "EE-87"
        }
    },
    "Egypt": {
        "countryCode": "EG",
        statesList: {
            "Ad Daqahliyah": "EG-DK",
            "Al Bahr al Ahmar": "EG-BA",
            "Al Buhayrah": "EG-BH",
            "Al Fayyum": "EG-FYM",
            "Al Gharbiyah": "EG-GH",
            "Al Iskandariyah": "EG-ALX",
            "Al Isma'iliyah": "EG-IS",
            "Al Jizah": "EG-GZ",
            "Al Minufiyah": "EG-MNF",
            "Al Minya": "EG-MN",
            "Al Qahirah": "EG-C",
            "Al Qalyubiyah": "EG-KB",
            "Al Uqsur": "EG-LX",
            "Al Wadi al Jadid": "EG-WAD",
            "As Suways": "EG-SUZ",
            "Ash Sharqiyah": "EG-SHR",
            "Aswan": "EG-ASN",
            "Asyut": "EG-AST",
            "Bani Suwayf": "EG-BNS",
            "Bur Sa'id": "EG-PTS",
            "Dumyat": "EG-DT",
            "Janub Sina'": "EG-JS",
            "Kafr ash Shaykh": "EG-KFS",
            "Matruh": "EG-MT",
            "Qina": "EG-KN",
            "Shamal Sina'": "EG-SIN",
            "Suhaj": "EG-SHG"
        }
    },
    "Western Sahara": {
        "countryCode": "EH",
        statesList: {}
    },
    "Eritrea": {
        "countryCode": "ER",
        statesList: {
            "Al Awsat": "ER-MA",
            "Qash-Barkah": "ER-GB"
        }
    },
    "Spain": {
        "countryCode": "ES",
        statesList: {
            "Andalucia": "ES-AN",
            "Aragon": "ES-AR",
            "Asturias, Principado de": "ES-AS",
            "Canarias": "ES-CN",
            "Cantabria": "ES-CB",
            "Castilla y Leon": "ES-CL",
            "Castilla-La Mancha": "ES-CM",
            "Catalunya": "ES-CT",
            "Ceuta": "ES-CE",
            "Extremadura": "ES-EX",
            "Galicia": "ES-GA",
            "Illes Balears": "ES-IB",
            "La Rioja": "ES-RI",
            "Madrid, Comunidad de": "ES-MD",
            "Melilla": "ES-ML",
            "Murcia, Region de": "ES-MC",
            "Navarra, Comunidad Foral de": "ES-NC",
            "Pais Vasco": "ES-PV",
            "Valenciana, Comunidad": "ES-VC"
        }
    },
    "Ethiopia": {
        "countryCode": "ET",
        statesList: {
            "Addis Ababa": "ET-AA",
            "Afar": "ET-AF",
            "Amara": "ET-AM",
            "Benshangul-Gumaz": "ET-BE",
            "Dire Dawa": "ET-DD",
            "Gambela Peoples": "ET-GA",
            "Harari People": "ET-HA",
            "Oromia": "ET-OR",
            "Somali": "ET-SO",
            "Southern Nations, Nationalities and Peoples": "ET-SN",
            "Tigrai": "ET-TI"
        }
    },
    "Finland": {
        "countryCode": "FI",
        statesList: {
            "Etela-Karjala": "FI-02",
            "Etela-Pohjanmaa": "FI-03",
            "Etela-Savo": "FI-04",
            "Kainuu": "FI-05",
            "Kanta-Hame": "FI-06",
            "Keski-Pohjanmaa": "FI-07",
            "Keski-Suomi": "FI-08",
            "Kymenlaakso": "FI-09",
            "Lappi": "FI-10",
            "Paijat-Hame": "FI-16",
            "Pirkanmaa": "FI-11",
            "Pohjanmaa": "FI-12",
            "Pohjois-Karjala": "FI-13",
            "Pohjois-Pohjanmaa": "FI-14",
            "Pohjois-Savo": "FI-15",
            "Satakunta": "FI-17",
            "Uusimaa": "FI-18",
            "Varsinais-Suomi": "FI-19"
        }
    },
    "Fiji": {
        "countryCode": "FJ",
        statesList: {
            "Central": "FJ-C",
            "Eastern": "FJ-E",
            "Northern": "FJ-N",
            "Rotuma": "FJ-R",
            "Western": "FJ-W"
        }
    },
    "Falkland Islands (Malvinas)": {
        "countryCode": "FK",
        statesList: {}
    },
    "Micronesia (Federated States of)": {
        "countryCode": "FM",
        statesList: {
            "Chuuk": "FM-TRK",
            "Kosrae": "FM-KSA",
            "Pohnpei": "FM-PNI",
            "Yap": "FM-YAP"
        }
    },
    "Faroe Islands": {
        "countryCode": "FO",
        statesList: {}
    },
    "France": {
        "countryCode": "FR",
        statesList: {
            "Auvergne-Rhone-Alpes": "FR-ARA",
            "Bourgogne-Franche-Comte": "FR-BFC",
            "Bretagne": "FR-BRE",
            "Centre-Val de Loire": "FR-CVL",
            "Corse": "FR-20R",
            "Grand-Est": "FR-GES",
            "Hauts-de-France": "FR-HDF",
            "Ile-de-France": "FR-IDF",
            "Normandie": "FR-NOR",
            "Nouvelle-Aquitaine": "FR-NAQ",
            "Occitanie": "FR-OCC",
            "Pays-de-la-Loire": "FR-PDL",
            "Provence-Alpes-Cote-d'Azur": "FR-PAC"
        }
    },
    "Gabon": {
        "countryCode": "GA",
        statesList: {
            "Estuaire": "GA-1",
            "Haut-Ogooue": "GA-2",
            "Moyen-Ogooue": "GA-3",
            "Ngounie": "GA-4",
            "Nyanga": "GA-5",
            "Ogooue-Lolo": "GA-7",
            "Ogooue-Maritime": "GA-8",
            "Woleu-Ntem": "GA-9"
        }
    },
    "United Kingdom of Great Britain and Northern Ireland": {
        "countryCode": "GB",
        statesList: {
            "England": "GB-ENG",
            "Northern Ireland": "GB-NIR",
            "Scotland": "GB-SCT",
            "Wales": "GB-WLS"
        }
    },
    "Grenada": {
        "countryCode": "GD",
        statesList: {
            "Saint Andrew": "GD-01",
            "Saint David": "GD-02",
            "Saint George": "GD-03",
            "Saint John": "GD-04",
            "Saint Mark": "GD-05",
            "Saint Patrick": "GD-06",
            "Southern Grenadine Islands": "GD-10"
        }
    },
    "Georgia": {
        "countryCode": "GE",
        statesList: {
            "Abkhazia": "GE-AB",
            "Ajaria": "GE-AJ",
            "Guria": "GE-GU",
            "Imereti": "GE-IM",
            "K'akheti": "GE-KA",
            "Kvemo Kartli": "GE-KK",
            "Mtskheta-Mtianeti": "GE-MM",
            "Rach'a-Lechkhumi-Kvemo Svaneti": "GE-RL",
            "Samegrelo-Zemo Svaneti": "GE-SZ",
            "Samtskhe-Javakheti": "GE-SJ",
            "Shida Kartli": "GE-SK",
            "Tbilisi": "GE-TB"
        }
    },
    "French Guiana": {
        "countryCode": "GF",
        statesList: {}
    },
    "Guernsey": {
        "countryCode": "GG",
        statesList: {}
    },
    "Ghana": {
        "countryCode": "GH",
        statesList: {
            "Ahafo": "GH-AF",
            "Ashanti": "GH-AH",
            "Bono": "GH-BO",
            "Bono East": "GH-BE",
            "Central": "GH-CP",
            "Eastern": "GH-EP",
            "Greater Accra": "GH-AA",
            "Northern": "GH-NP",
            "Upper East": "GH-UE",
            "Upper West": "GH-UW",
            "Volta": "GH-TV",
            "Western": "GH-WP"
        }
    },
    "Gibraltar": {
        "countryCode": "GI",
        statesList: {}
    },
    "Greenland": {
        "countryCode": "GL",
        statesList: {
            "Avannaata Kommunia": "GL-AV",
            "Kommune Kujalleq": "GL-KU",
            "Kommune Qeqertalik": "GL-QT",
            "Kommuneqarfik Sermersooq": "GL-SM",
            "Qeqqata Kommunia": "GL-QE"
        }
    },
    "Gambia": {
        "countryCode": "GM",
        statesList: {
            "Banjul": "GM-B",
            "Central River": "GM-M",
            "Lower River": "GM-L",
            "North Bank": "GM-N",
            "Upper River": "GM-U",
            "Western": "GM-W"
        }
    },
    "Guinea": {
        "countryCode": "GN",
        statesList: {
            "Boffa": "GN-BF",
            "Boke": "GN-B",
            "Conakry": "GN-C",
            "Dabola": "GN-DB",
            "Dinguiraye": "GN-DI",
            "Dubreka": "GN-DU",
            "Fria": "GN-FR",
            "Kankan": "GN-K",
            "Kissidougou": "GN-KS",
            "Kouroussa": "GN-KO",
            "Pita": "GN-PI",
            "Siguiri": "GN-SI"
        }
    },
    "Guadeloupe": {
        "countryCode": "GP",
        statesList: {}
    },
    "Equatorial Guinea": {
        "countryCode": "GQ",
        statesList: {
            "Bioko Norte": "GQ-BN",
            "Centro Sur": "GQ-CS",
            "Kie-Ntem": "GQ-KN",
            "Litoral": "GQ-LI",
            "Wele-Nzas": "GQ-WN"
        }
    },
    "Greece": {
        "countryCode": "GR",
        statesList: {
            "Agion Oros": "GR-69",
            "Anatoliki Makedonia kai Thraki": "GR-A",
            "Attiki": "GR-I",
            "Dytiki Ellada": "GR-G",
            "Dytiki Makedonia": "GR-C",
            "Ionia Nisia": "GR-F",
            "Ipeiros": "GR-D",
            "Kentriki Makedonia": "GR-B",
            "Kriti": "GR-M",
            "Notio Aigaio": "GR-L",
            "Peloponnisos": "GR-J",
            "Sterea Ellada": "GR-H",
            "Thessalia": "GR-E",
            "Voreio Aigaio": "GR-K"
        }
    },
    "South Georgia and the South Sandwich Islands": {
        "countryCode": "GS",
        statesList: {}
    },
    "Guatemala": {
        "countryCode": "GT",
        statesList: {
            "Alta Verapaz": "GT-16",
            "Baja Verapaz": "GT-15",
            "Chimaltenango": "GT-04",
            "Chiquimula": "GT-20",
            "El Progreso": "GT-02",
            "Escuintla": "GT-05",
            "Guatemala": "GT-01",
            "Huehuetenango": "GT-13",
            "Izabal": "GT-18",
            "Jalapa": "GT-21",
            "Jutiapa": "GT-22",
            "Peten": "GT-17",
            "Quetzaltenango": "GT-09",
            "Quiche": "GT-14",
            "Retalhuleu": "GT-11",
            "Sacatepequez": "GT-03",
            "San Marcos": "GT-12",
            "Santa Rosa": "GT-06",
            "Solola": "GT-07",
            "Suchitepequez": "GT-10",
            "Totonicapan": "GT-08",
            "Zacapa": "GT-19"
        }
    },
    "Guam": {
        "countryCode": "GU",
        statesList: {}
    },
    "Guinea-Bissau": {
        "countryCode": "GW",
        statesList: {
            "Bafata": "GW-BA",
            "Bissau": "GW-BS",
            "Cacheu": "GW-CA",
            "Gabu": "GW-GA",
            "Oio": "GW-OI"
        }
    },
    "Guyana": {
        "countryCode": "GY",
        statesList: {
            "Barima-Waini": "GY-BA",
            "Cuyuni-Mazaruni": "GY-CU",
            "Demerara-Mahaica": "GY-DE",
            "East Berbice-Corentyne": "GY-EB",
            "Essequibo Islands-West Demerara": "GY-ES",
            "Mahaica-Berbice": "GY-MA",
            "Potaro-Siparuni": "GY-PT",
            "Upper Demerara-Berbice": "GY-UD"
        }
    },
    "Hong Kong": {
        "countryCode": "HK",
        statesList: {}
    },
    "Heard Island and McDonald Islands": {
        "countryCode": "HM",
        statesList: {}
    },
    "Honduras": {
        "countryCode": "HN",
        statesList: {
            "Atlantida": "HN-AT",
            "Choluteca": "HN-CH",
            "Colon": "HN-CL",
            "Comayagua": "HN-CM",
            "Copan": "HN-CP",
            "Cortes": "HN-CR",
            "El Paraiso": "HN-EP",
            "Francisco Morazan": "HN-FM",
            "Gracias a Dios": "HN-GD",
            "Intibuca": "HN-IN",
            "Islas de la Bahia": "HN-IB",
            "La Paz": "HN-LP",
            "Lempira": "HN-LE",
            "Ocotepeque": "HN-OC",
            "Olancho": "HN-OL",
            "Santa Barbara": "HN-SB",
            "Valle": "HN-VA",
            "Yoro": "HN-YO"
        }
    },
    "Croatia": {
        "countryCode": "HR",
        statesList: {
            "Bjelovarsko-bilogorska zupanija": "HR-07",
            "Brodsko-posavska zupanija": "HR-12",
            "Dubrovacko-neretvanska zupanija": "HR-19",
            "Grad Zagreb": "HR-21",
            "Istarska zupanija": "HR-18",
            "Karlovacka zupanija": "HR-04",
            "Koprivnicko-krizevacka zupanija": "HR-06",
            "Krapinsko-zagorska zupanija": "HR-02",
            "Licko-senjska zupanija": "HR-09",
            "Medimurska zupanija": "HR-20",
            "Osjecko-baranjska zupanija": "HR-14",
            "Pozesko-slavonska zupanija": "HR-11",
            "Primorsko-goranska zupanija": "HR-08",
            "Sibensko-kninska zupanija": "HR-15",
            "Sisacko-moslavacka zupanija": "HR-03",
            "Splitsko-dalmatinska zupanija": "HR-17",
            "Varazdinska zupanija": "HR-05",
            "Viroviticko-podravska zupanija": "HR-10",
            "Vukovarsko-srijemska zupanija": "HR-16",
            "Zadarska zupanija": "HR-13",
            "Zagrebacka zupanija": "HR-01"
        }
    },
    "Haiti": {
        "countryCode": "HT",
        statesList: {
            "Artibonite": "HT-AR",
            "Centre": "HT-CE",
            "Grande'Anse": "HT-GA",
            "Nippes": "HT-NI",
            "Nord": "HT-ND",
            "Nord-Ouest": "HT-NO",
            "Ouest": "HT-OU",
            "Sud": "HT-SD",
            "Sud-Est": "HT-SE"
        }
    },
    "Hungary": {
        "countryCode": "HU",
        statesList: {
            "Bacs-Kiskun": "HU-BK",
            "Baranya": "HU-BA",
            "Bekes": "HU-BE",
            "Borsod-Abauj-Zemplen": "HU-BZ",
            "Budapest": "HU-BU",
            "Csongrad-Csanad": "HU-CS",
            "Fejer": "HU-FE",
            "Gyor-Moson-Sopron": "HU-GS",
            "Hajdu-Bihar": "HU-HB",
            "Heves": "HU-HE",
            "Jasz-Nagykun-Szolnok": "HU-JN",
            "Komarom-Esztergom": "HU-KE",
            "Nograd": "HU-NO",
            "Pest": "HU-PE",
            "Somogy": "HU-SO",
            "Szabolcs-Szatmar-Bereg": "HU-SZ",
            "Tolna": "HU-TO",
            "Vas": "HU-VA",
            "Veszprem": "HU-VE",
            "Zala": "HU-ZA"
        }
    },
    "Indonesia": {
        "countryCode": "ID",
        statesList: {
            "Aceh": "ID-AC",
            "Bali": "ID-BA",
            "Banten": "ID-BT",
            "Bengkulu": "ID-BE",
            "Gorontalo": "ID-GO",
            "Jakarta Raya": "ID-JK",
            "Jambi": "ID-JA",
            "Jawa Barat": "ID-JB",
            "Jawa Tengah": "ID-JT",
            "Jawa Timur": "ID-JI",
            "Kalimantan Barat": "ID-KB",
            "Kalimantan Selatan": "ID-KS",
            "Kalimantan Tengah": "ID-KT",
            "Kalimantan Timur": "ID-KI",
            "Kalimantan Utara": "ID-KU",
            "Kepulauan Bangka Belitung": "ID-BB",
            "Kepulauan Riau": "ID-KR",
            "Lampung": "ID-LA",
            "Maluku": "ID-ML",
            "Maluku Utara": "ID-MU",
            "Nusa Tenggara Barat": "ID-NB",
            "Nusa Tenggara Timur": "ID-NT",
            "Papua": "ID-PP",
            "Papua Barat": "ID-PB",
            "Papua Pengunungan": "ID-PE",
            "Papua Selatan": "ID-PS",
            "Papua Tengah": "ID-PT",
            "Riau": "ID-RI",
            "Sulawesi Barat": "ID-SR",
            "Sulawesi Selatan": "ID-SN",
            "Sulawesi Tengah": "ID-ST",
            "Sulawesi Tenggara": "ID-SG",
            "Sulawesi Utara": "ID-SA",
            "Sumatera Barat": "ID-SB",
            "Sumatera Selatan": "ID-SS",
            "Sumatera Utara": "ID-SU",
            "Yogyakarta": "ID-YO"
        }
    },
    "Ireland": {
        "countryCode": "IE",
        statesList: {
            "Carlow": "IE-CW",
            "Cavan": "IE-CN",
            "Clare": "IE-CE",
            "Cork": "IE-CO",
            "Donegal": "IE-DL",
            "Dublin": "IE-D",
            "Galway": "IE-G",
            "Kerry": "IE-KY",
            "Kildare": "IE-KE",
            "Kilkenny": "IE-KK",
            "Laois": "IE-LS",
            "Leitrim": "IE-LM",
            "Limerick": "IE-LK",
            "Longford": "IE-LD",
            "Louth": "IE-LH",
            "Mayo": "IE-MO",
            "Meath": "IE-MH",
            "Monaghan": "IE-MN",
            "Offaly": "IE-OY",
            "Roscommon": "IE-RN",
            "Sligo": "IE-SO",
            "Tipperary": "IE-TA",
            "Waterford": "IE-WD",
            "Westmeath": "IE-WH",
            "Wexford": "IE-WX",
            "Wicklow": "IE-WW"
        }
    },
    "Israel": {
        "countryCode": "IL",
        statesList: {
            "HaDarom": "IL-D",
            "HaMerkaz": "IL-M",
            "HaTsafon": "IL-Z",
            "Hefa": "IL-HA",
            "Tel Aviv": "IL-TA",
            "Yerushalayim": "IL-JM"
        }
    },
    "Isle of Man": {
        "countryCode": "IM",
        statesList: {}
    },
    "India": {
        "countryCode": "IN",
        statesList: {
            "Andaman and Nicobar Islands": "IN-AN",
            "Andhra Pradesh": "IN-AP",
            "Arunachal Pradesh": "IN-AR",
            "Assam": "IN-AS",
            "Bihar": "IN-BR",
            "Chandigarh": "IN-CH",
            "Chhattisgarh": "IN-CT",
            "Dadra and Nagar Haveli": "IN-DN",
            "Dadra and Nagar Haveli and Daman and Diu": "IN-DH",
            "Delhi": "IN-DL",
            "Goa": "IN-GA",
            "Gujarat": "IN-GJ",
            "Haryana": "IN-HR",
            "Himachal Pradesh": "IN-HP",
            "Jammu and Kashmir": "IN-JK",
            "Jharkhand": "IN-JH",
            "Karnataka": "IN-KA",
            "Kerala": "IN-KL",
            "Lakshadweep": "IN-LD",
            "Madhya Pradesh": "IN-MP",
            "Maharashtra": "IN-MH",
            "Manipur": "IN-MN",
            "Meghalaya": "IN-ML",
            "Mizoram": "IN-MZ",
            "Nagaland": "IN-NL",
            "Odisha": "IN-OR",
            "Puducherry": "IN-PY",
            "Punjab": "IN-PB",
            "Rajasthan": "IN-RJ",
            "Sikkim": "IN-SK",
            "Tamil Nadu": "IN-TN",
            "Telangana": "IN-TG",
            "Tripura": "IN-TR",
            "Uttar Pradesh": "IN-UP",
            "Uttarakhand": "IN-UT",
            "West Bengal": "IN-WB"
        }
    },
    "British Indian Ocean Territory": {
        "countryCode": "IO",
        statesList: {}
    },
    "Iraq": {
        "countryCode": "IQ",
        statesList: {
            "Al Anbar": "IQ-AN",
            "Al Basrah": "IQ-BA",
            "Al Muthanna": "IQ-MU",
            "Al Qadisiyah": "IQ-QA",
            "An Najaf": "IQ-NA",
            "Arbil": "IQ-AR",
            "As Sulaymaniyah": "IQ-SU",
            "Babil": "IQ-BB",
            "Baghdad": "IQ-BG",
            "Dahuk": "IQ-DA",
            "Dhi Qar": "IQ-DQ",
            "Diyala": "IQ-DI",
            "Karbala'": "IQ-KA",
            "Kirkuk": "IQ-KI",
            "Maysan": "IQ-MA",
            "Ninawa": "IQ-NI",
            "Salah ad Din": "IQ-SD",
            "Wasit": "IQ-WA"
        }
    },
    "Iran (Islamic Republic of)": {
        "countryCode": "IR",
        statesList: {
            "Alborz": "IR-30",
            "Ardabil": "IR-24",
            "Azarbayjan-e Gharbi": "IR-04",
            "Azarbayjan-e Sharqi": "IR-03",
            "Bushehr": "IR-18",
            "Chahar Mahal va Bakhtiari": "IR-14",
            "Esfahan": "IR-10",
            "Fars": "IR-07",
            "Gilan": "IR-01",
            "Golestan": "IR-27",
            "Hamadan": "IR-13",
            "Hormozgan": "IR-22",
            "Ilam": "IR-16",
            "Kerman": "IR-08",
            "Kermanshah": "IR-05",
            "Khorasan-e Jonubi": "IR-29",
            "Khorasan-e Razavi": "IR-09",
            "Khorasan-e Shomali": "IR-28",
            "Khuzestan": "IR-06",
            "Kohgiluyeh va Bowyer Ahmad": "IR-17",
            "Kordestan": "IR-12",
            "Lorestan": "IR-15",
            "Markazi": "IR-00",
            "Mazandaran": "IR-02",
            "Qazvin": "IR-26",
            "Qom": "IR-25",
            "Semnan": "IR-20",
            "Sistan va Baluchestan": "IR-11",
            "Tehran": "IR-23",
            "Yazd": "IR-21",
            "Zanjan": "IR-19"
        }
    },
    "Iceland": {
        "countryCode": "IS",
        statesList: {
            "Austurland": "IS-7",
            "Hofudborgarsvaedi": "IS-1",
            "Nordurland eystra": "IS-6",
            "Nordurland vestra": "IS-5",
            "Sudurland": "IS-8",
            "Sudurnes": "IS-2",
            "Vestfirdir": "IS-4",
            "Vesturland": "IS-3"
        }
    },
    "Italy": {
        "countryCode": "IT",
        statesList: {
            "Abruzzo": "IT-65",
            "Basilicata": "IT-77",
            "Calabria": "IT-78",
            "Campania": "IT-72",
            "Emilia-Romagna": "IT-45",
            "Friuli-Venezia Giulia": "IT-36",
            "Lazio": "IT-62",
            "Liguria": "IT-42",
            "Lombardia": "IT-25",
            "Marche": "IT-57",
            "Molise": "IT-67",
            "Piemonte": "IT-21",
            "Puglia": "IT-75",
            "Sardegna": "IT-88",
            "Sicilia": "IT-82",
            "Toscana": "IT-52",
            "Trentino-Alto Adige": "IT-32",
            "Umbria": "IT-55",
            "Valle d'Aosta": "IT-23",
            "Veneto": "IT-34"
        }
    },
    "Jersey": {
        "countryCode": "JE",
        statesList: {}
    },
    "Jamaica": {
        "countryCode": "JM",
        statesList: {
            "Clarendon": "JM-13",
            "Hanover": "JM-09",
            "Kingston": "JM-01",
            "Manchester": "JM-12",
            "Portland": "JM-04",
            "Saint Andrew": "JM-02",
            "Saint Ann": "JM-06",
            "Saint Catherine": "JM-14",
            "Saint Elizabeth": "JM-11",
            "Saint James": "JM-08",
            "Saint Mary": "JM-05",
            "Saint Thomas": "JM-03",
            "Trelawny": "JM-07",
            "Westmoreland": "JM-10"
        }
    },
    "Jordan": {
        "countryCode": "JO",
        statesList: {
            "'Ajlun": "JO-AJ",
            "Al 'Aqabah": "JO-AQ",
            "Al 'Asimah": "JO-AM",
            "Al Balqa'": "JO-BA",
            "Al Karak": "JO-KA",
            "Al Mafraq": "JO-MA",
            "At Tafilah": "JO-AT",
            "Az Zarqa'": "JO-AZ",
            "Irbid": "JO-IR",
            "Jarash": "JO-JA",
            "Ma'an": "JO-MN",
            "Madaba": "JO-MD"
        }
    },
    "Japan": {
        "countryCode": "JP",
        statesList: {
            "Aichi": "JP-23",
            "Akita": "JP-05",
            "Aomori": "JP-02",
            "Chiba": "JP-12",
            "Ehime": "JP-38",
            "Fukui": "JP-18",
            "Fukuoka": "JP-40",
            "Fukushima": "JP-07",
            "Gifu": "JP-21",
            "Gunma": "JP-10",
            "Hiroshima": "JP-34",
            "Hokkaido": "JP-01",
            "Hyogo": "JP-28",
            "Ibaraki": "JP-08",
            "Ishikawa": "JP-17",
            "Iwate": "JP-03",
            "Kagawa": "JP-37",
            "Kagoshima": "JP-46",
            "Kanagawa": "JP-14",
            "Kochi": "JP-39",
            "Kumamoto": "JP-43",
            "Kyoto": "JP-26",
            "Mie": "JP-24",
            "Miyagi": "JP-04",
            "Miyazaki": "JP-45",
            "Nagano": "JP-20",
            "Nagasaki": "JP-42",
            "Nara": "JP-29",
            "Niigata": "JP-15",
            "Oita": "JP-44",
            "Okayama": "JP-33",
            "Okinawa": "JP-47",
            "Osaka": "JP-27",
            "Saga": "JP-41",
            "Saitama": "JP-11",
            "Shiga": "JP-25",
            "Shimane": "JP-32",
            "Shizuoka": "JP-22",
            "Tochigi": "JP-09",
            "Tokushima": "JP-36",
            "Tokyo": "JP-13",
            "Tottori": "JP-31",
            "Toyama": "JP-16",
            "Wakayama": "JP-30",
            "Yamagata": "JP-06",
            "Yamaguchi": "JP-35",
            "Yamanashi": "JP-19"
        }
    },
    "Kenya": {
        "countryCode": "KE",
        statesList: {
            "Baringo": "KE-01",
            "Bomet": "KE-02",
            "Bungoma": "KE-03",
            "Busia": "KE-04",
            "Elgeyo/Marakwet": "KE-05",
            "Embu": "KE-06",
            "Garissa": "KE-07",
            "Homa Bay": "KE-08",
            "Isiolo": "KE-09",
            "Kajiado": "KE-10",
            "Kakamega": "KE-11",
            "Kericho": "KE-12",
            "Kiambu": "KE-13",
            "Kilifi": "KE-14",
            "Kirinyaga": "KE-15",
            "Kisii": "KE-16",
            "Kisumu": "KE-17",
            "Kitui": "KE-18",
            "Kwale": "KE-19",
            "Laikipia": "KE-20",
            "Lamu": "KE-21",
            "Machakos": "KE-22",
            "Makueni": "KE-23",
            "Mandera": "KE-24",
            "Marsabit": "KE-25",
            "Meru": "KE-26",
            "Migori": "KE-27",
            "Mombasa": "KE-28",
            "Murang'a": "KE-29",
            "Nairobi City": "KE-30",
            "Nakuru": "KE-31",
            "Nandi": "KE-32",
            "Narok": "KE-33",
            "Nyamira": "KE-34",
            "Nyandarua": "KE-35",
            "Nyeri": "KE-36",
            "Samburu": "KE-37",
            "Siaya": "KE-38",
            "Taita/Taveta": "KE-39",
            "Tana River": "KE-40",
            "Tharaka-Nithi": "KE-41",
            "Trans Nzoia": "KE-42",
            "Turkana": "KE-43",
            "Uasin Gishu": "KE-44",
            "Vihiga": "KE-45",
            "Wajir": "KE-46",
            "West Pokot": "KE-47"
        }
    },
    "Kyrgyzstan": {
        "countryCode": "KG",
        statesList: {
            "Batken": "KG-B",
            "Bishkek Shaary": "KG-GB",
            "Chuy": "KG-C",
            "Jalal-Abad": "KG-J",
            "Naryn": "KG-N",
            "Osh Shaary": "KG-GO",
            "Talas": "KG-T",
            "Ysyk-Kol": "KG-Y"
        }
    },
    "Cambodia": {
        "countryCode": "KH",
        statesList: {
            "Baat Dambang": "KH-2",
            "Banteay Mean Choay": "KH-1",
            "Kaeb": "KH-23",
            "Kampong Chaam": "KH-3",
            "Kampong Chhnang": "KH-4",
            "Kampong Spueu": "KH-5",
            "Kampong Thum": "KH-6",
            "Kampot": "KH-7",
            "Kandaal": "KH-8",
            "Kaoh Kong": "KH-9",
            "Kracheh": "KH-10",
            "Mondol Kiri": "KH-11",
            "Pailin": "KH-24",
            "Phnom Penh": "KH-12",
            "Pousaat": "KH-15",
            "Preah Sihanouk": "KH-18",
            "Preah Vihear": "KH-13",
            "Prey Veaeng": "KH-14",
            "Siem Reab": "KH-17",
            "Stueng Traeng": "KH-19",
            "Svaay Rieng": "KH-20",
            "Taakaev": "KH-21"
        }
    },
    "Kiribati": {
        "countryCode": "KI",
        statesList: {
            "Gilbert Islands": "KI-G",
            "Line Islands": "KI-L"
        }
    },
    "Comoros": {
        "countryCode": "KM",
        statesList: {
            "Grande Comore": "KM-G",
            "Moheli": "KM-M"
        }
    },
    "Saint Kitts and Nevis": {
        "countryCode": "KN",
        statesList: {
            "Christ Church Nichola Town": "KN-01",
            "Saint Anne Sandy Point": "KN-02",
            "Saint George Basseterre": "KN-03",
            "Saint James Windward": "KN-05",
            "Saint John Capisterre": "KN-06",
            "Saint John Figtree": "KN-07",
            "Saint Mary Cayon": "KN-08",
            "Saint Paul Capisterre": "KN-09",
            "Saint Paul Charlestown": "KN-10",
            "Saint Peter Basseterre": "KN-11",
            "Saint Thomas Middle Island": "KN-13",
            "Trinity Palmetto Point": "KN-15"
        }
    },
    "Korea (Democratic People's Republic of)": {
        "countryCode": "KP",
        statesList: {
            "P'yongyang": "KP-01"
        }
    },
    "Korea (Republic of)": {
        "countryCode": "KR",
        statesList: {
            "Busan-gwangyeoksi": "KR-26",
            "Chungcheongbuk-do": "KR-43",
            "Chungcheongnam-do": "KR-44",
            "Daegu-gwangyeoksi": "KR-27",
            "Daejeon-gwangyeoksi": "KR-30",
            "Gangwon-do": "KR-42",
            "Gwangju-gwangyeoksi": "KR-29",
            "Gyeonggi-do": "KR-41",
            "Gyeongsangbuk-do": "KR-47",
            "Gyeongsangnam-do": "KR-48",
            "Incheon-gwangyeoksi": "KR-28",
            "Jeju-teukbyeoljachido": "KR-49",
            "Jeollabuk-do": "KR-45",
            "Jeollanam-do": "KR-46",
            "Seoul-teukbyeolsi": "KR-11",
            "Ulsan-gwangyeoksi": "KR-31"
        }
    },
    "Kuwait": {
        "countryCode": "KW",
        statesList: {
            "Al 'Asimah": "KW-KU",
            "Al Ahmadi": "KW-AH",
            "Al Farwaniyah": "KW-FA",
            "Al Jahra'": "KW-JA",
            "Hawalli": "KW-HA",
            "Mubarak al Kabir": "KW-MU"
        }
    },
    "Cayman Islands": {
        "countryCode": "KY",
        statesList: {}
    },
    "Kazakhstan": {
        "countryCode": "KZ",
        statesList: {
            "Abay oblysy": "KZ-10",
            "Almaty": "KZ-75",
            "Almaty oblysy": "KZ-19",
            "Aqmola oblysy": "KZ-11",
            "Aqtobe oblysy": "KZ-15",
            "Astana": "KZ-71",
            "Atyrau oblysy": "KZ-23",
            "Batys Qazaqstan oblysy": "KZ-27",
            "Mangghystau oblysy": "KZ-47",
            "Pavlodar oblysy": "KZ-55",
            "Qaraghandy oblysy": "KZ-35",
            "Qostanay oblysy": "KZ-39",
            "Qyzylorda oblysy": "KZ-43",
            "Shyghys Qazaqstan oblysy": "KZ-63",
            "Shymkent": "KZ-79",
            "Soltustik Qazaqstan oblysy": "KZ-59",
            "Turkistan oblysy": "KZ-61",
            "Ulytau oblysy": "KZ-62",
            "Zhambyl oblysy": "KZ-31",
            "Zhetisu oblysy": "KZ-33"
        }
    },
    "Lao People's Democratic Republic": {
        "countryCode": "LA",
        statesList: {
            "Attapu": "LA-AT",
            "Bokeo": "LA-BK",
            "Bolikhamxai": "LA-BL",
            "Champasak": "LA-CH",
            "Khammouan": "LA-KH",
            "Louangphabang": "LA-LP",
            "Oudomxai": "LA-OU",
            "Phongsali": "LA-PH",
            "Savannakhet": "LA-SV",
            "Viangchan": "LA-VI",
            "Xaignabouli": "LA-XA",
            "Xekong": "LA-XE",
            "Xiangkhouang": "LA-XI"
        }
    },
    "Lebanon": {
        "countryCode": "LB",
        statesList: {
            "Aakkar": "LB-AK",
            "Baalbek-Hermel": "LB-BH",
            "Beqaa": "LB-BI",
            "Beyrouth": "LB-BA",
            "Liban-Nord": "LB-AS",
            "Liban-Sud": "LB-JA",
            "Mont-Liban": "LB-JL",
            "Nabatiye": "LB-NA"
        }
    },
    "Saint Lucia": {
        "countryCode": "LC",
        statesList: {
            "Anse la Raye": "LC-01",
            "Castries": "LC-02",
            "Choiseul": "LC-03",
            "Dennery": "LC-05",
            "Gros Islet": "LC-06",
            "Laborie": "LC-07",
            "Micoud": "LC-08",
            "Soufriere": "LC-10",
            "Vieux Fort": "LC-11"
        }
    },
    "Liechtenstein": {
        "countryCode": "LI",
        statesList: {
            "Balzers": "LI-01",
            "Eschen": "LI-02",
            "Gamprin": "LI-03",
            "Mauren": "LI-04",
            "Ruggell": "LI-06",
            "Schaan": "LI-07",
            "Triesen": "LI-09",
            "Triesenberg": "LI-10",
            "Vaduz": "LI-11"
        }
    },
    "Sri Lanka": {
        "countryCode": "LK",
        statesList: {
            "Central Province": "LK-2",
            "Eastern Province": "LK-5",
            "North Central Province": "LK-7",
            "North Western Province": "LK-6",
            "Northern Province": "LK-4",
            "Sabaragamuwa Province": "LK-9",
            "Southern Province": "LK-3",
            "Uva Province": "LK-8",
            "Western Province": "LK-1"
        }
    },
    "Liberia": {
        "countryCode": "LR",
        statesList: {
            "Bomi": "LR-BM",
            "Bong": "LR-BG",
            "Grand Bassa": "LR-GB",
            "Grand Gedeh": "LR-GG",
            "Margibi": "LR-MG",
            "Montserrado": "LR-MO",
            "Nimba": "LR-NI",
            "River Cess": "LR-RI",
            "Sinoe": "LR-SI"
        }
    },
    "Lesotho": {
        "countryCode": "LS",
        statesList: {
            "Berea": "LS-D",
            "Botha-Bothe": "LS-B",
            "Leribe": "LS-C",
            "Mafeteng": "LS-E",
            "Maseru": "LS-A",
            "Mohale's Hoek": "LS-F",
            "Mokhotlong": "LS-J",
            "Qacha's Nek": "LS-H",
            "Quthing": "LS-G",
            "Thaba-Tseka": "LS-K"
        }
    },
    "Lithuania": {
        "countryCode": "LT",
        statesList: {
            "Alytaus apskritis": "LT-AL",
            "Kauno apskritis": "LT-KU",
            "Klaipedos apskritis": "LT-KL",
            "Marijampoles apskritis": "LT-MR",
            "Panevezio apskritis": "LT-PN",
            "Siauliu apskritis": "LT-SA",
            "Taurages apskritis": "LT-TA",
            "Telsiu apskritis": "LT-TE",
            "Utenos apskritis": "LT-UT",
            "Vilniaus apskritis": "LT-VL"
        }
    },
    "Luxembourg": {
        "countryCode": "LU",
        statesList: {
            "Capellen": "LU-CA",
            "Clervaux": "LU-CL",
            "Diekirch": "LU-DI",
            "Echternach": "LU-EC",
            "Esch-sur-Alzette": "LU-ES",
            "Grevenmacher": "LU-GR",
            "Luxembourg": "LU-LU",
            "Mersch": "LU-ME",
            "Redange": "LU-RD",
            "Remich": "LU-RM",
            "Vianden": "LU-VD",
            "Wiltz": "LU-WI"
        }
    },
    "Latvia": {
        "countryCode": "LV",
        statesList: {
            "Adazu novads": "LV-011",
            "Aizkraukles novads": "LV-002",
            "Aluksnes novads": "LV-007",
            "Augsdaugavas novads": "LV-111",
            "Balvu novads": "LV-015",
            "Bauskas novads": "LV-016",
            "Cesu novads": "LV-022",
            "Daugavpils": "LV-DGV",
            "Dienvidkurzemes novads": "LV-112",
            "Dobeles novads": "LV-026",
            "Gulbenes novads": "LV-033",
            "Jekabpils novads": "LV-042",
            "Jelgava": "LV-JEL",
            "Jelgavas novads": "LV-041",
            "Jurmala": "LV-JUR",
            "Kekavas novads": "LV-052",
            "Kraslavas novads": "LV-047",
            "Kuldigas novads": "LV-050",
            "Liepaja": "LV-LPX",
            "Limbazu novads": "LV-054",
            "Livanu novads": "LV-056",
            "Ludzas novads": "LV-058",
            "Madonas novads": "LV-059",
            "Marupes novads": "LV-062",
            "Ogres novads": "LV-067",
            "Olaines novads": "LV-068",
            "Preilu novads": "LV-073",
            "Rezeknes novads": "LV-077",
            "Riga": "LV-RIX",
            "Ropazu novads": "LV-080",
            "Salaspils novads": "LV-087",
            "Saldus novads": "LV-088",
            "Saulkrastu novads": "LV-089",
            "Siguldas novads": "LV-091",
            "Smiltenes novads": "LV-094",
            "Talsu novads": "LV-097",
            "Tukuma novads": "LV-099",
            "Valkas novads": "LV-101",
            "Valmieras novads": "LV-113",
            "Varaklanu novads": "LV-102",
            "Ventspils novads": "LV-106"
        }
    },
    "Libya": {
        "countryCode": "LY",
        statesList: {
            "Al Butnan": "LY-BU",
            "Al Jabal al Akhdar": "LY-JA",
            "Al Jabal al Gharbi": "LY-JG",
            "Al Jafarah": "LY-JI",
            "Al Jufrah": "LY-JU",
            "Al Kufrah": "LY-KF",
            "Al Marj": "LY-MJ",
            "Al Marqab": "LY-MB",
            "Al Wahat": "LY-WA",
            "An Nuqat al Khams": "LY-NQ",
            "Az Zawiyah": "LY-ZA",
            "Banghazi": "LY-BA",
            "Darnah": "LY-DR",
            "Misratah": "LY-MI",
            "Nalut": "LY-NL",
            "Sabha": "LY-SB",
            "Surt": "LY-SR",
            "Tarabulus": "LY-TB",
            "Wadi ash Shati'": "LY-WS"
        }
    },
    "Morocco": {
        "countryCode": "MA",
        statesList: {
            "Beni-Mellal-Khenifra": "MA-05",
            "Casablanca-Settat": "MA-06",
            "Draa-Tafilalet": "MA-08",
            "Fes- Meknes": "MA-03",
            "Guelmim-Oued Noun (EH-partial)": "MA-10",
            "L'Oriental": "MA-02",
            "Laayoune-Sakia El Hamra (EH-partial)": "MA-11",
            "Marrakech-Safi": "MA-07",
            "Rabat-Sale-Kenitra": "MA-04",
            "Souss-Massa": "MA-09",
            "Tanger-Tetouan-Al Hoceima": "MA-01"
        }
    },
    "Monaco": {
        "countryCode": "MC",
        statesList: {
            "Fontvieille": "MC-FO",
            "La Condamine": "MC-CO",
            "Monaco-Ville": "MC-MO",
            "Moneghetti": "MC-MG",
            "Monte-Carlo": "MC-MC",
            "Saint-Roman": "MC-SR"
        }
    },
    "Moldova (Republic of)": {
        "countryCode": "MD",
        statesList: {
            "Anenii Noi": "MD-AN",
            "Balti": "MD-BA",
            "Basarabeasca": "MD-BS",
            "Bender": "MD-BD",
            "Briceni": "MD-BR",
            "Cahul": "MD-CA",
            "Calarasi": "MD-CL",
            "Cantemir": "MD-CT",
            "Causeni": "MD-CS",
            "Chisinau": "MD-CU",
            "Cimislia": "MD-CM",
            "Criuleni": "MD-CR",
            "Donduseni": "MD-DO",
            "Drochia": "MD-DR",
            "Dubasari": "MD-DU",
            "Edinet": "MD-ED",
            "Falesti": "MD-FA",
            "Floresti": "MD-FL",
            "Gagauzia, Unitatea teritoriala autonoma": "MD-GA",
            "Glodeni": "MD-GL",
            "Hincesti": "MD-HI",
            "Ialoveni": "MD-IA",
            "Leova": "MD-LE",
            "Nisporeni": "MD-NI",
            "Ocnita": "MD-OC",
            "Orhei": "MD-OR",
            "Rezina": "MD-RE",
            "Riscani": "MD-RI",
            "Singerei": "MD-SI",
            "Soldanesti": "MD-SD",
            "Soroca": "MD-SO",
            "Stefan Voda": "MD-SV",
            "Stinga Nistrului, unitatea teritoriala din": "MD-SN",
            "Straseni": "MD-ST",
            "Taraclia": "MD-TA",
            "Telenesti": "MD-TE",
            "Ungheni": "MD-UN"
        }
    },
    "Montenegro": {
        "countryCode": "ME",
        statesList: {
            "Andrijevica": "ME-01",
            "Bar": "ME-02",
            "Berane": "ME-03",
            "Bijelo Polje": "ME-04",
            "Budva": "ME-05",
            "Cetinje": "ME-06",
            "Danilovgrad": "ME-07",
            "Herceg-Novi": "ME-08",
            "Kolasin": "ME-09",
            "Kotor": "ME-10",
            "Niksic": "ME-12",
            "Plav": "ME-13",
            "Pljevlja": "ME-14",
            "Pluzine": "ME-15",
            "Podgorica": "ME-16",
            "Rozaje": "ME-17",
            "Tivat": "ME-19",
            "Tuzi": "ME-24",
            "Ulcinj": "ME-20",
            "Zabljak": "ME-21"
        }
    },
    "Saint Martin (French Part)": {
        "countryCode": "MF",
        statesList: {}
    },
    "Madagascar": {
        "countryCode": "MG",
        statesList: {
            "Antananarivo": "MG-T",
            "Antsiranana": "MG-D",
            "Fianarantsoa": "MG-F",
            "Mahajanga": "MG-M",
            "Toamasina": "MG-A",
            "Toliara": "MG-U"
        }
    },
    "Marshall Islands": {
        "countryCode": "MH",
        statesList: {
            "Kwajalein": "MH-KWA",
            "Majuro": "MH-MAJ"
        }
    },
    "North Macedonia": {
        "countryCode": "MK",
        statesList: {
            "Berovo": "MK-201",
            "Bitola": "MK-501",
            "Bogdanci": "MK-401",
            "Bogovinje": "MK-601",
            "Bosilovo": "MK-402",
            "Brvenica": "MK-602",
            "Butel": "MK-803",
            "Caska": "MK-109",
            "Centar": "MK-814",
            "Cesinovo-Oblesevo": "MK-210",
            "Cucer Sandevo": "MK-816",
            "Debar": "MK-303",
            "Demir Hisar": "MK-502",
            "Demir Kapija": "MK-103",
            "Dojran": "MK-406",
            "Dolneni": "MK-503",
            "Gazi Baba": "MK-804",
            "Gevgelija": "MK-405",
            "Gostivar": "MK-604",
            "Gradsko": "MK-102",
            "Ilinden": "MK-807",
            "Jegunovce": "MK-606",
            "Karbinci": "MK-205",
            "Kavadarci": "MK-104",
            "Kicevo": "MK-307",
            "Kisela Voda": "MK-809",
            "Kocani": "MK-206",
            "Kratovo": "MK-701",
            "Kriva Palanka": "MK-702",
            "Krusevo": "MK-505",
            "Kumanovo": "MK-703",
            "Lipkovo": "MK-704",
            "Lozovo": "MK-105",
            "Makedonska Kamenica": "MK-207",
            "Makedonski Brod": "MK-308",
            "Mavrovo i Rostusa": "MK-607",
            "Mogila": "MK-506",
            "Negotino": "MK-106",
            "Novaci": "MK-507",
            "Novo Selo": "MK-408",
            "Ohrid": "MK-310",
            "Pehcevo": "MK-208",
            "Petrovec": "MK-810",
            "Plasnica": "MK-311",
            "Prilep": "MK-508",
            "Probistip": "MK-209",
            "Radovis": "MK-409",
            "Rankovce": "MK-705",
            "Resen": "MK-509",
            "Rosoman": "MK-107",
            "Saraj": "MK-811",
            "Sopiste": "MK-812",
            "Stip": "MK-211",
            "Struga": "MK-312",
            "Strumica": "MK-410",
            "Studenicani": "MK-813",
            "Sveti Nikole": "MK-108",
            "Tearce": "MK-608",
            "Tetovo": "MK-609",
            "Valandovo": "MK-403",
            "Vasilevo": "MK-404",
            "Veles": "MK-101",
            "Vevcani": "MK-301",
            "Vinica": "MK-202",
            "Zelenikovo": "MK-806",
            "Zelino": "MK-605"
        }
    },
    "Mali": {
        "countryCode": "ML",
        statesList: {
            "Bamako": "ML-BKO",
            "Gao": "ML-7",
            "Kayes": "ML-1",
            "Kidal": "ML-8",
            "Koulikoro": "ML-2",
            "Mopti": "ML-5",
            "Segou": "ML-4",
            "Sikasso": "ML-3",
            "Tombouctou": "ML-6"
        }
    },
    "Myanmar": {
        "countryCode": "MM",
        statesList: {
            "Ayeyarwady": "MM-07",
            "Bago": "MM-02",
            "Kachin": "MM-11",
            "Kayah": "MM-12",
            "Kayin": "MM-13",
            "Magway": "MM-03",
            "Mandalay": "MM-04",
            "Mon": "MM-15",
            "Nay Pyi Taw": "MM-18",
            "Rakhine": "MM-16",
            "Sagaing": "MM-01",
            "Shan": "MM-17",
            "Tanintharyi": "MM-05",
            "Yangon": "MM-06"
        }
    },
    "Mongolia": {
        "countryCode": "MN",
        statesList: {
            "Bayanhongor": "MN-069",
            "Dornod": "MN-061",
            "Govi-Altay": "MN-065",
            "Hovd": "MN-043",
            "Orhon": "MN-035",
            "Ovorhangay": "MN-055",
            "Selenge": "MN-049",
            "Tov": "MN-047",
            "Ulaanbaatar": "MN-1"
        }
    },
    "Macao": {
        "countryCode": "MO",
        statesList: {}
    },
    "Northern Mariana Islands": {
        "countryCode": "MP",
        statesList: {}
    },
    "Martinique": {
        "countryCode": "MQ",
        statesList: {}
    },
    "Mauritania": {
        "countryCode": "MR",
        statesList: {
            "Adrar": "MR-07",
            "Assaba": "MR-03",
            "Brakna": "MR-05",
            "Dakhlet Nouadhibou": "MR-08",
            "Gorgol": "MR-04",
            "Hodh ech Chargui": "MR-01",
            "Hodh el Gharbi": "MR-02",
            "Inchiri": "MR-12",
            "Nouakchott Ouest": "MR-13",
            "Tagant": "MR-09",
            "Tiris Zemmour": "MR-11",
            "Trarza": "MR-06"
        }
    },
    "Montserrat": {
        "countryCode": "MS",
        statesList: {}
    },
    "Malta": {
        "countryCode": "MT",
        statesList: {
            "Attard": "MT-01",
            "Balzan": "MT-02",
            "Birgu": "MT-03",
            "Birkirkara": "MT-04",
            "Birzebbuga": "MT-05",
            "Bormla": "MT-06",
            "Dingli": "MT-07",
            "Fgura": "MT-08",
            "Floriana": "MT-09",
            "Fontana": "MT-10",
            "Ghajnsielem": "MT-13",
            "Gharb": "MT-14",
            "Gharghur": "MT-15",
            "Ghasri": "MT-16",
            "Ghaxaq": "MT-17",
            "Gudja": "MT-11",
            "Gzira": "MT-12",
            "Hamrun": "MT-18",
            "Iklin": "MT-19",
            "Isla": "MT-20",
            "Kalkara": "MT-21",
            "Kirkop": "MT-23",
            "Lija": "MT-24",
            "Luqa": "MT-25",
            "Marsa": "MT-26",
            "Marsaskala": "MT-27",
            "Marsaxlokk": "MT-28",
            "Mdina": "MT-29",
            "Mellieha": "MT-30",
            "Mgarr": "MT-31",
            "Mosta": "MT-32",
            "Mqabba": "MT-33",
            "Msida": "MT-34",
            "Mtarfa": "MT-35",
            "Munxar": "MT-36",
            "Nadur": "MT-37",
            "Naxxar": "MT-38",
            "Paola": "MT-39",
            "Pembroke": "MT-40",
            "Pieta": "MT-41",
            "Qala": "MT-42",
            "Qormi": "MT-43",
            "Qrendi": "MT-44",
            "Rabat Gozo": "MT-45",
            "Rabat Malta": "MT-46",
            "Safi": "MT-47",
            "Saint John": "MT-49",
            "Saint Julian's": "MT-48",
            "Saint Lawrence": "MT-50",
            "Saint Lucia's": "MT-53",
            "Saint Paul's Bay": "MT-51",
            "Sannat": "MT-52",
            "Santa Venera": "MT-54",
            "Siggiewi": "MT-55",
            "Sliema": "MT-56",
            "Swieqi": "MT-57",
            "Ta' Xbiex": "MT-58",
            "Tarxien": "MT-59",
            "Valletta": "MT-60",
            "Xaghra": "MT-61",
            "Xewkija": "MT-62",
            "Xghajra": "MT-63",
            "Zabbar": "MT-64",
            "Zebbug Gozo": "MT-65",
            "Zejtun": "MT-67",
            "Zurrieq": "MT-68"
        }
    },
    "Mauritius": {
        "countryCode": "MU",
        statesList: {
            "Black River": "MU-BL",
            "Flacq": "MU-FL",
            "Grand Port": "MU-GP",
            "Moka": "MU-MO",
            "Pamplemousses": "MU-PA",
            "Plaines Wilhems": "MU-PW",
            "Port Louis": "MU-PL",
            "Riviere du Rempart": "MU-RR",
            "Rodrigues Islands": "MU-RO",
            "Savanne": "MU-SA"
        }
    },
    "Maldives": {
        "countryCode": "MV",
        statesList: {
            "Addu City": "MV-01",
            "Faadhippolhu": "MV-03",
            "Felidhu Atoll": "MV-04",
            "Hahdhunmathi": "MV-05",
            "Male": "MV-MLE",
            "Mulaku Atoll": "MV-12",
            "North Ari Atoll": "MV-02",
            "North Maalhosmadulu": "MV-13",
            "North Thiladhunmathi": "MV-07",
            "South Ari Atoll": "MV-00",
            "South Huvadhu Atoll": "MV-28",
            "South Maalhosmadulu": "MV-20",
            "South Miladhunmadulu": "MV-25",
            "South Thiladhunmathi": "MV-23"
        }
    },
    "Malawi": {
        "countryCode": "MW",
        statesList: {
            "Balaka": "MW-BA",
            "Blantyre": "MW-BL",
            "Chikwawa": "MW-CK",
            "Chiradzulu": "MW-CR",
            "Dedza": "MW-DE",
            "Dowa": "MW-DO",
            "Karonga": "MW-KR",
            "Lilongwe": "MW-LI",
            "Machinga": "MW-MH",
            "Mangochi": "MW-MG",
            "Mchinji": "MW-MC",
            "Mwanza": "MW-MW",
            "Mzimba": "MW-MZ",
            "Neno": "MW-NE",
            "Nkhotakota": "MW-NK",
            "Ntchisi": "MW-NI",
            "Salima": "MW-SA",
            "Thyolo": "MW-TH",
            "Zomba": "MW-ZO"
        }
    },
    "Mexico": {
        "countryCode": "MX",
        statesList: {
            "Aguascalientes": "MX-AGU",
            "Baja California": "MX-BCN",
            "Baja California Sur": "MX-BCS",
            "Campeche": "MX-CAM",
            "Chiapas": "MX-CHP",
            "Chihuahua": "MX-CHH",
            "Ciudad de Mexico": "MX-CMX",
            "Coahuila de Zaragoza": "MX-COA",
            "Colima": "MX-COL",
            "Durango": "MX-DUR",
            "Guanajuato": "MX-GUA",
            "Guerrero": "MX-GRO",
            "Hidalgo": "MX-HID",
            "Jalisco": "MX-JAL",
            "Mexico": "MX-MEX",
            "Michoacan de Ocampo": "MX-MIC",
            "Morelos": "MX-MOR",
            "Nayarit": "MX-NAY",
            "Nuevo Leon": "MX-NLE",
            "Oaxaca": "MX-OAX",
            "Puebla": "MX-PUE",
            "Queretaro": "MX-QUE",
            "Quintana Roo": "MX-ROO",
            "San Luis Potosi": "MX-SLP",
            "Sinaloa": "MX-SIN",
            "Sonora": "MX-SON",
            "Tabasco": "MX-TAB",
            "Tamaulipas": "MX-TAM",
            "Tlaxcala": "MX-TLA",
            "Veracruz de Ignacio de la Llave": "MX-VER",
            "Yucatan": "MX-YUC",
            "Zacatecas": "MX-ZAC"
        }
    },
    "Malaysia": {
        "countryCode": "MY",
        statesList: {
            "Johor": "MY-01",
            "Kedah": "MY-02",
            "Kelantan": "MY-03",
            "Melaka": "MY-04",
            "Negeri Sembilan": "MY-05",
            "Pahang": "MY-06",
            "Perak": "MY-08",
            "Perlis": "MY-09",
            "Pulau Pinang": "MY-07",
            "Sabah": "MY-12",
            "Sarawak": "MY-13",
            "Selangor": "MY-10",
            "Terengganu": "MY-11",
            "Wilayah Persekutuan Kuala Lumpur": "MY-14",
            "Wilayah Persekutuan Labuan": "MY-15",
            "Wilayah Persekutuan Putrajaya": "MY-16"
        }
    },
    "Mozambique": {
        "countryCode": "MZ",
        statesList: {
            "Cabo Delgado": "MZ-P",
            "Gaza": "MZ-G",
            "Inhambane": "MZ-I",
            "Manica": "MZ-B",
            "Maputo": "MZ-L",
            "Nampula": "MZ-N",
            "Niassa": "MZ-A",
            "Sofala": "MZ-S",
            "Tete": "MZ-T",
            "Zambezia": "MZ-Q"
        }
    },
    "Namibia": {
        "countryCode": "NA",
        statesList: {
            "Erongo": "NA-ER",
            "Hardap": "NA-HA",
            "Karas": "NA-KA",
            "Kavango East": "NA-KE",
            "Kavango West": "NA-KW",
            "Khomas": "NA-KH",
            "Kunene": "NA-KU",
            "Ohangwena": "NA-OW",
            "Omaheke": "NA-OH",
            "Omusati": "NA-OS",
            "Oshana": "NA-ON",
            "Oshikoto": "NA-OT",
            "Otjozondjupa": "NA-OD",
            "Zambezi": "NA-CA"
        }
    },
    "New Caledonia": {
        "countryCode": "NC",
        statesList: {}
    },
    "Niger": {
        "countryCode": "NE",
        statesList: {
            "Agadez": "NE-1",
            "Diffa": "NE-2",
            "Niamey": "NE-8",
            "Tahoua": "NE-5",
            "Tillaberi": "NE-6",
            "Zinder": "NE-7"
        }
    },
    "Norfolk Island": {
        "countryCode": "NF",
        statesList: {}
    },
    "Nigeria": {
        "countryCode": "NG",
        statesList: {
            "Abia": "NG-AB",
            "Abuja Federal Capital Territory": "NG-FC",
            "Adamawa": "NG-AD",
            "Akwa Ibom": "NG-AK",
            "Anambra": "NG-AN",
            "Bauchi": "NG-BA",
            "Bayelsa": "NG-BY",
            "Benue": "NG-BE",
            "Borno": "NG-BO",
            "Cross River": "NG-CR",
            "Delta": "NG-DE",
            "Ebonyi": "NG-EB",
            "Edo": "NG-ED",
            "Ekiti": "NG-EK",
            "Enugu": "NG-EN",
            "Gombe": "NG-GO",
            "Imo": "NG-IM",
            "Jigawa": "NG-JI",
            "Kaduna": "NG-KD",
            "Kano": "NG-KN",
            "Katsina": "NG-KT",
            "Kebbi": "NG-KE",
            "Kogi": "NG-KO",
            "Kwara": "NG-KW",
            "Lagos": "NG-LA",
            "Nasarawa": "NG-NA",
            "Niger": "NG-NI",
            "Ogun": "NG-OG",
            "Ondo": "NG-ON",
            "Osun": "NG-OS",
            "Oyo": "NG-OY",
            "Plateau": "NG-PL",
            "Rivers": "NG-RI",
            "Sokoto": "NG-SO",
            "Taraba": "NG-TA",
            "Yobe": "NG-YO",
            "Zamfara": "NG-ZA"
        }
    },
    "Nicaragua": {
        "countryCode": "NI",
        statesList: {
            "Boaco": "NI-BO",
            "Carazo": "NI-CA",
            "Chinandega": "NI-CI",
            "Chontales": "NI-CO",
            "Costa Caribe Norte": "NI-AN",
            "Costa Caribe Sur": "NI-AS",
            "Esteli": "NI-ES",
            "Granada": "NI-GR",
            "Jinotega": "NI-JI",
            "Leon": "NI-LE",
            "Madriz": "NI-MD",
            "Managua": "NI-MN",
            "Masaya": "NI-MS",
            "Matagalpa": "NI-MT",
            "Nueva Segovia": "NI-NS",
            "Rio San Juan": "NI-SJ",
            "Rivas": "NI-RI"
        }
    },
    "Netherlands": {
        "countryCode": "NL",
        statesList: {
            "Drenthe": "NL-DR",
            "Flevoland": "NL-FL",
            "Fryslan": "NL-FR",
            "Gelderland": "NL-GE",
            "Groningen": "NL-GR",
            "Limburg": "NL-LI",
            "Noord-Brabant": "NL-NB",
            "Noord-Holland": "NL-NH",
            "Overijssel": "NL-OV",
            "Utrecht": "NL-UT",
            "Zeeland": "NL-ZE",
            "Zuid-Holland": "NL-ZH"
        }
    },
    "Norway": {
        "countryCode": "NO",
        statesList: {
            "Agder": "NO-42",
            "Innlandet": "NO-34",
            "More og Romsdal": "NO-15",
            "Nordland": "NO-18",
            "Oslo": "NO-03",
            "Rogaland": "NO-11",
            "Troms og Finnmark": "NO-54",
            "Trondelag": "NO-50",
            "Vestfold og Telemark": "NO-38",
            "Vestland": "NO-46",
            "Viken": "NO-30"
        }
    },
    "Nepal": {
        "countryCode": "NP",
        statesList: {
            "Bagmati": "NP-BA",
            "Bheri": "NP-BH",
            "Dhawalagiri": "NP-DH",
            "Gandaki": "NP-GA",
            "Janakpur": "NP-JA",
            "Karnali": "NP-KA",
            "Kosi": "NP-KO",
            "Lumbini": "NP-LU",
            "Mahakali": "NP-MA",
            "Mechi": "NP-ME",
            "Narayani": "NP-NA",
            "Rapti": "NP-RA",
            "Sagarmatha": "NP-SA",
            "Seti": "NP-SE"
        }
    },
    "Nauru": {
        "countryCode": "NR",
        statesList: {
            "Aiwo": "NR-01",
            "Anetan": "NR-03",
            "Yaren": "NR-14"
        }
    },
    "Niue": {
        "countryCode": "NU",
        statesList: {}
    },
    "New Zealand": {
        "countryCode": "NZ",
        statesList: {
            "Auckland": "NZ-AUK",
            "Bay of Plenty": "NZ-BOP",
            "Canterbury": "NZ-CAN",
            "Chatham Islands Territory": "NZ-CIT",
            "Gisborne": "NZ-GIS",
            "Hawke's Bay": "NZ-HKB",
            "Manawatu-Wanganui": "NZ-MWT",
            "Marlborough": "NZ-MBH",
            "Nelson": "NZ-NSN",
            "Northland": "NZ-NTL",
            "Otago": "NZ-OTA",
            "Southland": "NZ-STL",
            "Taranaki": "NZ-TKI",
            "Tasman": "NZ-TAS",
            "Waikato": "NZ-WKO",
            "Wellington": "NZ-WGN",
            "West Coast": "NZ-WTC"
        }
    },
    "Oman": {
        "countryCode": "OM",
        statesList: {
            "Ad Dakhiliyah": "OM-DA",
            "Al Buraymi": "OM-BU",
            "Al Wusta": "OM-WU",
            "Az Zahirah": "OM-ZA",
            "Janub al Batinah": "OM-BJ",
            "Janub ash Sharqiyah": "OM-SJ",
            "Masqat": "OM-MA",
            "Musandam": "OM-MU",
            "Shamal al Batinah": "OM-BS",
            "Shamal ash Sharqiyah": "OM-SS",
            "Zufar": "OM-ZU"
        }
    },
    "Panama": {
        "countryCode": "PA",
        statesList: {
            "Bocas del Toro": "PA-1",
            "Chiriqui": "PA-4",
            "Cocle": "PA-2",
            "Colon": "PA-3",
            "Darien": "PA-5",
            "Herrera": "PA-6",
            "Los Santos": "PA-7",
            "Ngobe-Bugle": "PA-NB",
            "Panama": "PA-8",
            "Veraguas": "PA-9"
        }
    },
    "Peru": {
        "countryCode": "PE",
        statesList: {
            "Amazonas": "PE-AMA",
            "Ancash": "PE-ANC",
            "Apurimac": "PE-APU",
            "Arequipa": "PE-ARE",
            "Ayacucho": "PE-AYA",
            "Cajamarca": "PE-CAJ",
            "Cusco": "PE-CUS",
            "El Callao": "PE-CAL",
            "Huancavelica": "PE-HUV",
            "Huanuco": "PE-HUC",
            "Ica": "PE-ICA",
            "Junin": "PE-JUN",
            "La Libertad": "PE-LAL",
            "Lambayeque": "PE-LAM",
            "Lima": "PE-LIM",
            "Loreto": "PE-LOR",
            "Madre de Dios": "PE-MDD",
            "Moquegua": "PE-MOQ",
            "Pasco": "PE-PAS",
            "Piura": "PE-PIU",
            "Puno": "PE-PUN",
            "San Martin": "PE-SAM",
            "Tacna": "PE-TAC",
            "Tumbes": "PE-TUM",
            "Ucayali": "PE-UCA"
        }
    },
    "French Polynesia": {
        "countryCode": "PF",
        statesList: {}
    },
    "Papua New Guinea": {
        "countryCode": "PG",
        statesList: {
            "Bougainville": "PG-NSB",
            "Central": "PG-CPM",
            "Chimbu": "PG-CPK",
            "East New Britain": "PG-EBR",
            "East Sepik": "PG-ESW",
            "Eastern Highlands": "PG-EHG",
            "Madang": "PG-MPM",
            "Manus": "PG-MRL",
            "Milne Bay": "PG-MBA",
            "Morobe": "PG-MPL",
            "National Capital District (Port Moresby)": "PG-NCD",
            "New Ireland": "PG-NIK",
            "Southern Highlands": "PG-SHM",
            "West New Britain": "PG-WBK",
            "West Sepik": "PG-SAN",
            "Western": "PG-WPD",
            "Western Highlands": "PG-WHM"
        }
    },
    "Philippines": {
        "countryCode": "PH",
        statesList: {
            "Abra": "PH-ABR",
            "Agusan del Norte": "PH-AGN",
            "Agusan del Sur": "PH-AGS",
            "Aklan": "PH-AKL",
            "Albay": "PH-ALB",
            "Antique": "PH-ANT",
            "Apayao": "PH-APA",
            "Aurora": "PH-AUR",
            "Basilan": "PH-BAS",
            "Bataan": "PH-BAN",
            "Batanes": "PH-BTN",
            "Batangas": "PH-BTG",
            "Benguet": "PH-BEN",
            "Biliran": "PH-BIL",
            "Bohol": "PH-BOH",
            "Bukidnon": "PH-BUK",
            "Bulacan": "PH-BUL",
            "Cagayan": "PH-CAG",
            "Camarines Norte": "PH-CAN",
            "Camarines Sur": "PH-CAS",
            "Camiguin": "PH-CAM",
            "Capiz": "PH-CAP",
            "Catanduanes": "PH-CAT",
            "Cavite": "PH-CAV",
            "Cebu": "PH-CEB",
            "Cotabato": "PH-NCO",
            "Davao Oriental": "PH-DAO",
            "Davao de Oro": "PH-COM",
            "Davao del Norte": "PH-DAV",
            "Davao del Sur": "PH-DAS",
            "Dinagat Islands": "PH-DIN",
            "Eastern Samar": "PH-EAS",
            "Guimaras": "PH-GUI",
            "Ifugao": "PH-IFU",
            "Ilocos Norte": "PH-ILN",
            "Ilocos Sur": "PH-ILS",
            "Iloilo": "PH-ILI",
            "Isabela": "PH-ISA",
            "Kalinga": "PH-KAL",
            "La Union": "PH-LUN",
            "Laguna": "PH-LAG",
            "Lanao del Norte": "PH-LAN",
            "Lanao del Sur": "PH-LAS",
            "Leyte": "PH-LEY",
            "Maguindanao": "PH-MAG",
            "Marinduque": "PH-MAD",
            "Masbate": "PH-MAS",
            "Mindoro Occidental": "PH-MDC",
            "Mindoro Oriental": "PH-MDR",
            "Misamis Occidental": "PH-MSC",
            "Misamis Oriental": "PH-MSR",
            "Mountain Province": "PH-MOU",
            "National Capital Region": "PH-00",
            "Negros Occidental": "PH-NEC",
            "Negros Oriental": "PH-NER",
            "Northern Samar": "PH-NSA",
            "Nueva Ecija": "PH-NUE",
            "Nueva Vizcaya": "PH-NUV",
            "Palawan": "PH-PLW",
            "Pampanga": "PH-PAM",
            "Pangasinan": "PH-PAN",
            "Quezon": "PH-QUE",
            "Quirino": "PH-QUI",
            "Rizal": "PH-RIZ",
            "Romblon": "PH-ROM",
            "Samar": "PH-WSA",
            "Sarangani": "PH-SAR",
            "Siquijor": "PH-SIG",
            "Sorsogon": "PH-SOR",
            "South Cotabato": "PH-SCO",
            "Southern Leyte": "PH-SLE",
            "Sultan Kudarat": "PH-SUK",
            "Sulu": "PH-SLU",
            "Surigao del Norte": "PH-SUN",
            "Surigao del Sur": "PH-SUR",
            "Tarlac": "PH-TAR",
            "Tawi-Tawi": "PH-TAW",
            "Zambales": "PH-ZMB",
            "Zamboanga Sibugay": "PH-ZSI",
            "Zamboanga del Norte": "PH-ZAN",
            "Zamboanga del Sur": "PH-ZAS"
        }
    },
    "Pakistan": {
        "countryCode": "PK",
        statesList: {
            "Azad Jammu and Kashmir": "PK-JK",
            "Balochistan": "PK-BA",
            "Gilgit-Baltistan": "PK-GB",
            "Islamabad": "PK-IS",
            "Khyber Pakhtunkhwa": "PK-KP",
            "Punjab": "PK-PB",
            "Sindh": "PK-SD"
        }
    },
    "Poland": {
        "countryCode": "PL",
        statesList: {
            "Dolnoslaskie": "PL-02",
            "Kujawsko-pomorskie": "PL-04",
            "Lodzkie": "PL-10",
            "Lubelskie": "PL-06",
            "Lubuskie": "PL-08",
            "Malopolskie": "PL-12",
            "Mazowieckie": "PL-14",
            "Opolskie": "PL-16",
            "Podkarpackie": "PL-18",
            "Podlaskie": "PL-20",
            "Pomorskie": "PL-22",
            "Slaskie": "PL-24",
            "Swietokrzyskie": "PL-26",
            "Warminsko-mazurskie": "PL-28",
            "Wielkopolskie": "PL-30",
            "Zachodniopomorskie": "PL-32"
        }
    },
    "Saint Pierre and Miquelon": {
        "countryCode": "PM",
        statesList: {}
    },
    "Pitcairn": {
        "countryCode": "PN",
        statesList: {}
    },
    "Puerto Rico": {
        "countryCode": "PR",
        statesList: {}
    },
    "Palestine": {
        "countryCode": "PS",
        statesList: {
            "Bethlehem": "PS-BTH",
            "Deir El Balah": "PS-DEB",
            "Gaza": "PS-GZA",
            "Hebron": "PS-HBN",
            "Jenin": "PS-JEN",
            "Jericho and Al Aghwar": "PS-JRH",
            "Jerusalem": "PS-JEM",
            "Khan Yunis": "PS-KYS",
            "Nablus": "PS-NBS",
            "Qalqilya": "PS-QQA",
            "Rafah": "PS-RFH",
            "Ramallah": "PS-RBH",
            "Salfit": "PS-SLT",
            "Tubas": "PS-TBS",
            "Tulkarm": "PS-TKM"
        }
    },
    "Portugal": {
        "countryCode": "PT",
        statesList: {
            "Aveiro": "PT-01",
            "Beja": "PT-02",
            "Braga": "PT-03",
            "Braganca": "PT-04",
            "Castelo Branco": "PT-05",
            "Coimbra": "PT-06",
            "Evora": "PT-07",
            "Faro": "PT-08",
            "Guarda": "PT-09",
            "Leiria": "PT-10",
            "Lisboa": "PT-11",
            "Portalegre": "PT-12",
            "Porto": "PT-13",
            "Regiao Autonoma da Madeira": "PT-30",
            "Regiao Autonoma dos Acores": "PT-20",
            "Santarem": "PT-14",
            "Setubal": "PT-15",
            "Viana do Castelo": "PT-16",
            "Vila Real": "PT-17",
            "Viseu": "PT-18"
        }
    },
    "Palau": {
        "countryCode": "PW",
        statesList: {
            "Airai": "PW-004",
            "Koror": "PW-150",
            "Melekeok": "PW-212"
        }
    },
    "Paraguay": {
        "countryCode": "PY",
        statesList: {
            "Alto Parana": "PY-10",
            "Amambay": "PY-13",
            "Asuncion": "PY-ASU",
            "Boqueron": "PY-19",
            "Caaguazu": "PY-5",
            "Caazapa": "PY-6",
            "Canindeyu": "PY-14",
            "Central": "PY-11",
            "Concepcion": "PY-1",
            "Cordillera": "PY-3",
            "Guaira": "PY-4",
            "Itapua": "PY-7",
            "Misiones": "PY-8",
            "Neembucu": "PY-12",
            "Paraguari": "PY-9",
            "Presidente Hayes": "PY-15",
            "San Pedro": "PY-2"
        }
    },
    "Qatar": {
        "countryCode": "QA",
        statesList: {
            "Ad Dawhah": "QA-DA",
            "Al Khawr wa adh Dhakhirah": "QA-KH",
            "Al Wakrah": "QA-WA",
            "Ar Rayyan": "QA-RA",
            "Ash Shamal": "QA-MS",
            "Az Za'ayin": "QA-ZA",
            "Umm Salal": "QA-US"
        }
    },
    "Reunion": {
        "countryCode": "RE",
        statesList: {}
    },
    "Romania": {
        "countryCode": "RO",
        statesList: {
            "Alba": "RO-AB",
            "Arad": "RO-AR",
            "Arges": "RO-AG",
            "Bacau": "RO-BC",
            "Bihor": "RO-BH",
            "Bistrita-Nasaud": "RO-BN",
            "Botosani": "RO-BT",
            "Braila": "RO-BR",
            "Brasov": "RO-BV",
            "Bucuresti": "RO-B",
            "Buzau": "RO-BZ",
            "Calarasi": "RO-CL",
            "Caras-Severin": "RO-CS",
            "Cluj": "RO-CJ",
            "Constanta": "RO-CT",
            "Covasna": "RO-CV",
            "Dambovita": "RO-DB",
            "Dolj": "RO-DJ",
            "Galati": "RO-GL",
            "Giurgiu": "RO-GR",
            "Gorj": "RO-GJ",
            "Harghita": "RO-HR",
            "Hunedoara": "RO-HD",
            "Ialomita": "RO-IL",
            "Iasi": "RO-IS",
            "Ilfov": "RO-IF",
            "Maramures": "RO-MM",
            "Mehedinti": "RO-MH",
            "Mures": "RO-MS",
            "Neamt": "RO-NT",
            "Olt": "RO-OT",
            "Prahova": "RO-PH",
            "Salaj": "RO-SJ",
            "Satu Mare": "RO-SM",
            "Sibiu": "RO-SB",
            "Suceava": "RO-SV",
            "Teleorman": "RO-TR",
            "Timis": "RO-TM",
            "Tulcea": "RO-TL",
            "Valcea": "RO-VL",
            "Vaslui": "RO-VS",
            "Vrancea": "RO-VN"
        }
    },
    "Serbia": {
        "countryCode": "RS",
        statesList: {
            "Beograd": "RS-00",
            "Borski okrug": "RS-14",
            "Branicevski okrug": "RS-11",
            "Jablanicki okrug": "RS-23",
            "Juznobacki okrug": "RS-06",
            "Juznobanatski okrug": "RS-04",
            "Kolubarski okrug": "RS-09",
            "Kosovsko-Mitrovacki okrug": "RS-28",
            "Macvanski okrug": "RS-08",
            "Moravicki okrug": "RS-17",
            "Nisavski okrug": "RS-20",
            "Pcinjski okrug": "RS-24",
            "Pecki okrug": "RS-26",
            "Pirotski okrug": "RS-22",
            "Podunavski okrug": "RS-10",
            "Pomoravski okrug": "RS-13",
            "Prizrenski okrug": "RS-27",
            "Rasinski okrug": "RS-19",
            "Raski okrug": "RS-18",
            "Severnobacki okrug": "RS-01",
            "Severnobanatski okrug": "RS-03",
            "Srednjebanatski okrug": "RS-02",
            "Sremski okrug": "RS-07",
            "Sumadijski okrug": "RS-12",
            "Toplicki okrug": "RS-21",
            "Zajecarski okrug": "RS-15",
            "Zapadnobacki okrug": "RS-05",
            "Zlatiborski okrug": "RS-16"
        }
    },
    "Russian Federation": {
        "countryCode": "RU",
        statesList: {
            "Adygeya, Respublika": "RU-AD",
            "Altay, Respublika": "RU-AL",
            "Altayskiy kray": "RU-ALT",
            "Amurskaya oblast'": "RU-AMU",
            "Arkhangel'skaya oblast'": "RU-ARK",
            "Astrakhanskaya oblast'": "RU-AST",
            "Bashkortostan, Respublika": "RU-BA",
            "Belgorodskaya oblast'": "RU-BEL",
            "Bryanskaya oblast'": "RU-BRY",
            "Buryatiya, Respublika": "RU-BU",
            "Chechenskaya Respublika": "RU-CE",
            "Chelyabinskaya oblast'": "RU-CHE",
            "Chukotskiy avtonomnyy okrug": "RU-CHU",
            "Chuvashskaya Respublika": "RU-CU",
            "Dagestan, Respublika": "RU-DA",
            "Ingushetiya, Respublika": "RU-IN",
            "Irkutskaya oblast'": "RU-IRK",
            "Ivanovskaya oblast'": "RU-IVA",
            "Kabardino-Balkarskaya Respublika": "RU-KB",
            "Kaliningradskaya oblast'": "RU-KGD",
            "Kalmykiya, Respublika": "RU-KL",
            "Kaluzhskaya oblast'": "RU-KLU",
            "Kamchatskiy kray": "RU-KAM",
            "Karachayevo-Cherkesskaya Respublika": "RU-KC",
            "Kareliya, Respublika": "RU-KR",
            "Kemerovskaya oblast'": "RU-KEM",
            "Khabarovskiy kray": "RU-KHA",
            "Khakasiya, Respublika": "RU-KK",
            "Khanty-Mansiyskiy avtonomnyy okrug": "RU-KHM",
            "Kirovskaya oblast'": "RU-KIR",
            "Komi, Respublika": "RU-KO",
            "Kostromskaya oblast'": "RU-KOS",
            "Krasnodarskiy kray": "RU-KDA",
            "Krasnoyarskiy kray": "RU-KYA",
            "Kurganskaya oblast'": "RU-KGN",
            "Kurskaya oblast'": "RU-KRS",
            "Leningradskaya oblast'": "RU-LEN",
            "Lipetskaya oblast'": "RU-LIP",
            "Magadanskaya oblast'": "RU-MAG",
            "Mariy El, Respublika": "RU-ME",
            "Mordoviya, Respublika": "RU-MO",
            "Moskovskaya oblast'": "RU-MOS",
            "Moskva": "RU-MOW",
            "Murmanskaya oblast'": "RU-MUR",
            "Nenetskiy avtonomnyy okrug": "RU-NEN",
            "Nizhegorodskaya oblast'": "RU-NIZ",
            "Novgorodskaya oblast'": "RU-NGR",
            "Novosibirskaya oblast'": "RU-NVS",
            "Omskaya oblast'": "RU-OMS",
            "Orenburgskaya oblast'": "RU-ORE",
            "Orlovskaya oblast'": "RU-ORL",
            "Penzenskaya oblast'": "RU-PNZ",
            "Permskiy kray": "RU-PER",
            "Primorskiy kray": "RU-PRI",
            "Pskovskaya oblast'": "RU-PSK",
            "Rostovskaya oblast'": "RU-ROS",
            "Ryazanskaya oblast'": "RU-RYA",
            "Saha, Respublika": "RU-SA",
            "Sakhalinskaya oblast'": "RU-SAK",
            "Samarskaya oblast'": "RU-SAM",
            "Sankt-Peterburg": "RU-SPE",
            "Saratovskaya oblast'": "RU-SAR",
            "Severnaya Osetiya, Respublika": "RU-SE",
            "Smolenskaya oblast'": "RU-SMO",
            "Stavropol'skiy kray": "RU-STA",
            "Sverdlovskaya oblast'": "RU-SVE",
            "Tambovskaya oblast'": "RU-TAM",
            "Tatarstan, Respublika": "RU-TA",
            "Tomskaya oblast'": "RU-TOM",
            "Tul'skaya oblast'": "RU-TUL",
            "Tverskaya oblast'": "RU-TVE",
            "Tyumenskaya oblast'": "RU-TYU",
            "Tyva, Respublika": "RU-TY",
            "Udmurtskaya Respublika": "RU-UD",
            "Ul'yanovskaya oblast'": "RU-ULY",
            "Vladimirskaya oblast'": "RU-VLA",
            "Volgogradskaya oblast'": "RU-VGG",
            "Vologodskaya oblast'": "RU-VLG",
            "Voronezhskaya oblast'": "RU-VOR",
            "Yamalo-Nenetskiy avtonomnyy okrug": "RU-YAN",
            "Yaroslavskaya oblast'": "RU-YAR",
            "Yevreyskaya avtonomnaya oblast'": "RU-YEV",
            "Zabaykal'skiy kray": "RU-ZAB"
        }
    },
    "Rwanda": {
        "countryCode": "RW",
        statesList: {
            "Est": "RW-02",
            "Nord": "RW-03",
            "Ouest": "RW-04",
            "Sud": "RW-05",
            "Ville de Kigali": "RW-01"
        }
    },
    "Saudi Arabia": {
        "countryCode": "SA",
        statesList: {
            "'Asir": "SA-14",
            "Al Bahah": "SA-11",
            "Al Hudud ash Shamaliyah": "SA-08",
            "Al Jawf": "SA-12",
            "Al Madinah al Munawwarah": "SA-03",
            "Al Qasim": "SA-05",
            "Ar Riyad": "SA-01",
            "Ash Sharqiyah": "SA-04",
            "Ha'il": "SA-06",
            "Jazan": "SA-09",
            "Makkah al Mukarramah": "SA-02",
            "Najran": "SA-10",
            "Tabuk": "SA-07"
        }
    },
    "Solomon Islands": {
        "countryCode": "SB",
        statesList: {
            "Choiseul": "SB-CH",
            "Guadalcanal": "SB-GU",
            "Western": "SB-WE"
        }
    },
    "Seychelles": {
        "countryCode": "SC",
        statesList: {
            "Anse Boileau": "SC-02",
            "Anse Royale": "SC-05",
            "Anse aux Pins": "SC-01",
            "Baie Lazare": "SC-06",
            "Baie Sainte Anne": "SC-07",
            "Beau Vallon": "SC-08",
            "Bel Ombre": "SC-10",
            "Cascade": "SC-11",
            "English River": "SC-16",
            "Grand Anse Mahe": "SC-13",
            "Grand Anse Praslin": "SC-14",
            "La Digue": "SC-15",
            "Pointe Larue": "SC-20",
            "Takamaka": "SC-23"
        }
    },
    "Sudan": {
        countryCode: "SD",
        statesList: {
            "Blue Nile": "SD-NB",
            "Central Darfur": "SD-DC",
            "Gedaref": "SD-GD",
            "Gezira": "SD-GZ",
            "Kassala": "SD-KA",
            "Khartoum": "SD-KH",
            "North Darfur": "SD-DN",
            "North Kordofan": "SD-KN",
            "Northern": "SD-NO",
            "Red Sea": "SD-RS",
            "River Nile": "SD-NR",
            "Sennar": "SD-SI",
            "South Darfur": "SD-DS",
            "South Kordofan": "SD-KS",
            "West Darfur": "SD-DW",
            "West Kordofan": "SD-GK",
            "White Nile": "SD-NW"
        }
    },
    "Sweden": {
        "countryCode": "SE",
        statesList: {
            "Blekinge lan": "SE-K",
            "Dalarnas lan": "SE-W",
            "Gavleborgs lan": "SE-X",
            "Gotlands lan": "SE-I",
            "Hallands lan": "SE-N",
            "Jamtlands lan": "SE-Z",
            "Jonkopings lan": "SE-F",
            "Kalmar lan": "SE-H",
            "Kronobergs lan": "SE-G",
            "Norrbottens lan": "SE-BD",
            "Orebro lan": "SE-T",
            "Ostergotlands lan": "SE-E",
            "Skane lan": "SE-M",
            "Sodermanlands lan": "SE-D",
            "Stockholms lan": "SE-AB",
            "Uppsala lan": "SE-C",
            "Varmlands lan": "SE-S",
            "Vasterbottens lan": "SE-AC",
            "Vasternorrlands lan": "SE-Y",
            "Vastmanlands lan": "SE-U",
            "Vastra Gotalands lan": "SE-O"
        }
    },
    "Singapore": {
        "countryCode": "SG",
        statesList: {}
    },
    "Saint Helena": {
        "countryCode": "SH",
        statesList: {
            "Saint Helena": "SH-HL"
        }
    },
    "Slovenia": {
        "countryCode": "SI",
        statesList: {
            "Ajdovscina": "SI-001",
            "Ankaran": "SI-213",
            "Apace": "SI-195",
            "Beltinci": "SI-002",
            "Benedikt": "SI-148",
            "Bistrica ob Sotli": "SI-149",
            "Bled": "SI-003",
            "Bloke": "SI-150",
            "Bohinj": "SI-004",
            "Borovnica": "SI-005",
            "Bovec": "SI-006",
            "Braslovce": "SI-151",
            "Brda": "SI-007",
            "Brezice": "SI-009",
            "Brezovica": "SI-008",
            "Cankova": "SI-152",
            "Celje": "SI-011",
            "Cerklje na Gorenjskem": "SI-012",
            "Cerknica": "SI-013",
            "Cerkno": "SI-014",
            "Cirkulane": "SI-196",
            "Crensovci": "SI-015",
            "Crnomelj": "SI-017",
            "Destrnik": "SI-018",
            "Divaca": "SI-019",
            "Dobje": "SI-154",
            "Dobrepolje": "SI-020",
            "Dobrna": "SI-155",
            "Dobrova-Polhov Gradec": "SI-021",
            "Dobrovnik": "SI-156",
            "Domzale": "SI-023",
            "Dornava": "SI-024",
            "Dravograd": "SI-025",
            "Duplek": "SI-026",
            "Gorje": "SI-207",
            "Gornja Radgona": "SI-029",
            "Gornji Petrovci": "SI-031",
            "Grad": "SI-158",
            "Grosuplje": "SI-032",
            "Hajdina": "SI-159",
            "Hoce-Slivnica": "SI-160",
            "Hodos": "SI-161",
            "Horjul": "SI-162",
            "Hrastnik": "SI-034",
            "Hrpelje-Kozina": "SI-035",
            "Idrija": "SI-036",
            "Ig": "SI-037",
            "Ilirska Bistrica": "SI-038",
            "Ivancna Gorica": "SI-039",
            "Izola": "SI-040",
            "Jesenice": "SI-041",
            "Jursinci": "SI-042",
            "Kamnik": "SI-043",
            "Kanal": "SI-044",
            "Kidricevo": "SI-045",
            "Kobarid": "SI-046",
            "Kobilje": "SI-047",
            "Kocevje": "SI-048",
            "Komen": "SI-049",
            "Komenda": "SI-164",
            "Koper": "SI-050",
            "Kosanjevica na Krki": "SI-197",
            "Kostel": "SI-165",
            "Kranj": "SI-052",
            "Kranjska Gora": "SI-053",
            "Krizevci": "SI-166",
            "Krsko": "SI-054",
            "Kungota": "SI-055",
            "Kuzma": "SI-056",
            "Lasko": "SI-057",
            "Lenart": "SI-058",
            "Lendava": "SI-059",
            "Litija": "SI-060",
            "Ljubljana": "SI-061",
            "Ljutomer": "SI-063",
            "Log-Dragomer": "SI-208",
            "Logatec": "SI-064",
            "Loska dolina": "SI-065",
            "Loski Potok": "SI-066",
            "Lovrenc na Pohorju": "SI-167",
            "Luce": "SI-067",
            "Lukovica": "SI-068",
            "Majsperk": "SI-069",
            "Makole": "SI-198",
            "Maribor": "SI-070",
            "Markovci": "SI-168",
            "Medvode": "SI-071",
            "Menges": "SI-072",
            "Metlika": "SI-073",
            "Mezica": "SI-074",
            "Miklavz na Dravskem polju": "SI-169",
            "Miren-Kostanjevica": "SI-075",
            "Mirna": "SI-212",
            "Mirna Pec": "SI-170",
            "Mislinja": "SI-076",
            "Mokronog-Trebelno": "SI-199",
            "Moravce": "SI-077",
            "Mozirje": "SI-079",
            "Murska Sobota": "SI-080",
            "Muta": "SI-081",
            "Naklo": "SI-082",
            "Nazarje": "SI-083",
            "Nova Gorica": "SI-084",
            "Novo Mesto": "SI-085",
            "Odranci": "SI-086",
            "Oplotnica": "SI-171",
            "Ormoz": "SI-087",
            "Piran": "SI-090",
            "Pivka": "SI-091",
            "Podcetrtek": "SI-092",
            "Podlehnik": "SI-172",
            "Poljcane": "SI-200",
            "Polzela": "SI-173",
            "Postojna": "SI-094",
            "Prebold": "SI-174",
            "Preddvor": "SI-095",
            "Prevalje": "SI-175",
            "Ptuj": "SI-096",
            "Puconci": "SI-097",
            "Race-Fram": "SI-098",
            "Radece": "SI-099",
            "Radenci": "SI-100",
            "Radlje ob Dravi": "SI-101",
            "Radovljica": "SI-102",
            "Ravne na Koroskem": "SI-103",
            "Razkrizje": "SI-176",
            "Recica ob Savinji": "SI-209",
            "Rence-Vogrsko": "SI-201",
            "Ribnica": "SI-104",
            "Rogaska Slatina": "SI-106",
            "Rogasovci": "SI-105",
            "Ruse": "SI-108",
            "Salovci": "SI-033",
            "Semic": "SI-109",
            "Sempeter-Vrtojba": "SI-183",
            "Sencur": "SI-117",
            "Sentilj": "SI-118",
            "Sentjernej": "SI-119",
            "Sentjur": "SI-120",
            "Sentrupert": "SI-211",
            "Sevnica": "SI-110",
            "Sezana": "SI-111",
            "Skocjan": "SI-121",
            "Skofja Loka": "SI-122",
            "Skofljica": "SI-123",
            "Slovenj Gradec": "SI-112",
            "Slovenska Bistrica": "SI-113",
            "Slovenske Konjice": "SI-114",
            "Smarje pri Jelsah": "SI-124",
            "Smarjeske Toplice": "SI-206",
            "Smartno ob Paki": "SI-125",
            "Smartno pri Litiji": "SI-194",
            "Sodrazica": "SI-179",
            "Solcava": "SI-180",
            "Sostanj": "SI-126",
            "Starse": "SI-115",
            "Store": "SI-127",
            "Straza": "SI-203",
            "Sveta Trojica v Slovenskih goricah": "SI-204",
            "Sveti Andraz v Slovenskih Goricah": "SI-182",
            "Sveti Jurij ob Scavnici": "SI-116",
            "Sveti Jurij v Slovenskih goricah": "SI-210",
            "Sveti Tomaz": "SI-205",
            "Tabor": "SI-184",
            "Tisina": "SI-010",
            "Tolmin": "SI-128",
            "Trbovlje": "SI-129",
            "Trebnje": "SI-130",
            "Trnovska Vas": "SI-185",
            "Trzic": "SI-131",
            "Trzin": "SI-186",
            "Turnisce": "SI-132",
            "Velenje": "SI-133",
            "Velika Polana": "SI-187",
            "Velike Lasce": "SI-134",
            "Verzej": "SI-188",
            "Videm": "SI-135",
            "Vipava": "SI-136",
            "Vitanje": "SI-137",
            "Vodice": "SI-138",
            "Vojnik": "SI-139",
            "Vransko": "SI-189",
            "Vrhnika": "SI-140",
            "Vuzenica": "SI-141",
            "Zagorje ob Savi": "SI-142",
            "Zalec": "SI-190",
            "Zavrc": "SI-143",
            "Zelezniki": "SI-146",
            "Zetale": "SI-191",
            "Ziri": "SI-147",
            "Zrece": "SI-144",
            "Zuzemberk": "SI-193"
        }
    },
    "Svalbard and Jan Mayen": {
        "countryCode": "SJ",
        statesList: {}
    },
    "Slovakia": {
        "countryCode": "SK",
        statesList: {
            "Banskobystricky kraj": "SK-BC",
            "Bratislavsky kraj": "SK-BL",
            "Kosicky kraj": "SK-KI",
            "Nitriansky kraj": "SK-NI",
            "Presovsky kraj": "SK-PV",
            "Trenciansky kraj": "SK-TC",
            "Trnavsky kraj": "SK-TA",
            "Zilinsky kraj": "SK-ZI"
        }
    },
    "Sierra Leone": {
        "countryCode": "SL",
        statesList: {
            "Eastern": "SL-E",
            "Northern": "SL-N",
            "Southern": "SL-S",
            "Western Area": "SL-W"
        }
    },
    "San Marino": {
        "countryCode": "SM",
        statesList: {
            "Chiesanuova": "SM-02",
            "Citta di San Marino": "SM-07",
            "Faetano": "SM-04",
            "Serravalle": "SM-09"
        }
    },
    "Senegal": {
        "countryCode": "SN",
        statesList: {
            "Dakar": "SN-DK",
            "Diourbel": "SN-DB",
            "Fatick": "SN-FK",
            "Kaffrine": "SN-KA",
            "Kaolack": "SN-KL",
            "Kedougou": "SN-KE",
            "Kolda": "SN-KD",
            "Louga": "SN-LG",
            "Matam": "SN-MT",
            "Saint-Louis": "SN-SL",
            "Sedhiou": "SN-SE",
            "Tambacounda": "SN-TC",
            "Thies": "SN-TH",
            "Ziguinchor": "SN-ZG"
        }
    },
    "Somalia": {
        "countryCode": "SO",
        statesList: {
            "Awdal": "SO-AW",
            "Banaadir": "SO-BN",
            "Bari": "SO-BR",
            "Bay": "SO-BY",
            "Jubbada Hoose": "SO-JH",
            "Mudug": "SO-MU",
            "Nugaal": "SO-NU",
            "Sool": "SO-SO",
            "Togdheer": "SO-TO",
            "Woqooyi Galbeed": "SO-WO"
        }
    },
    "Suriname": {
        "countryCode": "SR",
        statesList: {
            "Brokopondo": "SR-BR",
            "Commewijne": "SR-CM",
            "Coronie": "SR-CR",
            "Nickerie": "SR-NI",
            "Para": "SR-PR",
            "Paramaribo": "SR-PM",
            "Saramacca": "SR-SA",
            "Sipaliwini": "SR-SI",
            "Wanica": "SR-WA"
        }
    },
    "South Sudan": {
        "countryCode": "SS",
        statesList: {
            "Central Equatoria": "SS-EC",
            "Eastern Equatoria": "SS-EE",
            "Northern Bahr el Ghazal": "SS-BN",
            "Unity": "SS-UY",
            "Upper Nile": "SS-NU",
            "Western Equatoria": "SS-EW"
        }
    },
    "Sao Tome and Principe": {
        "countryCode": "ST",
        statesList: {
            "Agua Grande": "ST-01"
        }
    },
    "El Salvador": {
        "countryCode": "SV",
        statesList: {
            "Ahuachapan": "SV-AH",
            "Cabanas": "SV-CA",
            "Chalatenango": "SV-CH",
            "Cuscatlan": "SV-CU",
            "La Libertad": "SV-LI",
            "La Paz": "SV-PA",
            "La Union": "SV-UN",
            "Morazan": "SV-MO",
            "San Miguel": "SV-SM",
            "San Salvador": "SV-SS",
            "San Vicente": "SV-SV",
            "Santa Ana": "SV-SA",
            "Sonsonate": "SV-SO",
            "Usulutan": "SV-US"
        }
    },
    "Sint Maarten (Dutch Part)": {
        "countryCode": "SX",
        statesList: {}
    },
    "Syrian Arab Republic": {
        "countryCode": "SY",
        statesList: {
            "Al Hasakah": "SY-HA",
            "Al Ladhiqiyah": "SY-LA",
            "Al Qunaytirah": "SY-QU",
            "Ar Raqqah": "SY-RA",
            "As Suwayda'": "SY-SU",
            "Dar'a": "SY-DR",
            "Dayr az Zawr": "SY-DY",
            "Dimashq": "SY-DI",
            "Halab": "SY-HL",
            "Hamah": "SY-HM",
            "Hims": "SY-HI",
            "Rif Dimashq": "SY-RD",
            "Tartus": "SY-TA"
        }
    },
    "Eswatini": {
        "countryCode": "SZ",
        statesList: {
            "Hhohho": "SZ-HH",
            "Lubombo": "SZ-LU",
            "Manzini": "SZ-MA",
            "Shiselweni": "SZ-SH"
        }
    },
    "Turks and Caicos Islands": {
        "countryCode": "TC",
        statesList: {}
    },
    "Chad": {
        "countryCode": "TD",
        statesList: {
            "Bahr el Ghazal": "TD-BG",
            "Chari-Baguirmi": "TD-CB",
            "Lac": "TD-LC",
            "Ouaddai": "TD-OD",
            "Sila": "TD-SI",
            "Ville de Ndjamena": "TD-ND"
        }
    },
    "French Southern Territories": {
        "countryCode": "TF",
        statesList: {}
    },
    "Togo": {
        "countryCode": "TG",
        statesList: {
            "Centrale": "TG-C",
            "Kara": "TG-K",
            "Maritime": "TG-M",
            "Plateaux": "TG-P",
            "Savanes": "TG-S"
        }
    },
    "Thailand": {
        "countryCode": "TH",
        statesList: {
            "Amnat Charoen": "TH-37",
            "Ang Thong": "TH-15",
            "Bueng Kan": "TH-38",
            "Buri Ram": "TH-31",
            "Chachoengsao": "TH-24",
            "Chai Nat": "TH-18",
            "Chaiyaphum": "TH-36",
            "Chanthaburi": "TH-22",
            "Chiang Mai": "TH-50",
            "Chiang Rai": "TH-57",
            "Chon Buri": "TH-20",
            "Chumphon": "TH-86",
            "Kalasin": "TH-46",
            "Kamphaeng Phet": "TH-62",
            "Kanchanaburi": "TH-71",
            "Khon Kaen": "TH-40",
            "Krabi": "TH-81",
            "Krung Thep Maha Nakhon": "TH-10",
            "Lampang": "TH-52",
            "Lamphun": "TH-51",
            "Loei": "TH-42",
            "Lop Buri": "TH-16",
            "Mae Hong Son": "TH-58",
            "Maha Sarakham": "TH-44",
            "Mukdahan": "TH-49",
            "Nakhon Nayok": "TH-26",
            "Nakhon Pathom": "TH-73",
            "Nakhon Phanom": "TH-48",
            "Nakhon Ratchasima": "TH-30",
            "Nakhon Sawan": "TH-60",
            "Nakhon Si Thammarat": "TH-80",
            "Nan": "TH-55",
            "Narathiwat": "TH-96",
            "Nong Bua Lam Phu": "TH-39",
            "Nong Khai": "TH-43",
            "Nonthaburi": "TH-12",
            "Pathum Thani": "TH-13",
            "Pattani": "TH-94",
            "Phangnga": "TH-82",
            "Phatthalung": "TH-93",
            "Phayao": "TH-56",
            "Phetchabun": "TH-67",
            "Phetchaburi": "TH-76",
            "Phichit": "TH-66",
            "Phitsanulok": "TH-65",
            "Phra Nakhon Si Ayutthaya": "TH-14",
            "Phrae": "TH-54",
            "Phuket": "TH-83",
            "Prachin Buri": "TH-25",
            "Prachuap Khiri Khan": "TH-77",
            "Ranong": "TH-85",
            "Ratchaburi": "TH-70",
            "Rayong": "TH-21",
            "Roi Et": "TH-45",
            "Sa Kaeo": "TH-27",
            "Sakon Nakhon": "TH-47",
            "Samut Prakan": "TH-11",
            "Samut Sakhon": "TH-74",
            "Samut Songkhram": "TH-75",
            "Saraburi": "TH-19",
            "Satun": "TH-91",
            "Si Sa Ket": "TH-33",
            "Sing Buri": "TH-17",
            "Songkhla": "TH-90",
            "Sukhothai": "TH-64",
            "Suphan Buri": "TH-72",
            "Surat Thani": "TH-84",
            "Surin": "TH-32",
            "Tak": "TH-63",
            "Trang": "TH-92",
            "Trat": "TH-23",
            "Ubon Ratchathani": "TH-34",
            "Udon Thani": "TH-41",
            "Uthai Thani": "TH-61",
            "Uttaradit": "TH-53",
            "Yala": "TH-95",
            "Yasothon": "TH-35"
        }
    },
    "Tajikistan": {
        "countryCode": "TJ",
        statesList: {
            "Dushanbe": "TJ-DU",
            "Khatlon": "TJ-KT",
            "Kuhistoni Badakhshon": "TJ-GB",
            "Nohiyahoi Tobei Jumhuri": "TJ-RA",
            "Sughd": "TJ-SU"
        }
    },
    "Tokelau": {
        "countryCode": "TK",
        statesList: {}
    },
    "Timor-Leste": {
        "countryCode": "TL",
        statesList: {
            "Aileu": "TL-AL",
            "Ainaro": "TL-AN",
            "Cova Lima": "TL-CO",
            "Dili": "TL-DI",
            "Liquica": "TL-LI"
        }
    },
    "Turkmenistan": {
        "countryCode": "TM",
        statesList: {
            "Ahal": "TM-A",
            "Balkan": "TM-B",
            "Dasoguz": "TM-D",
            "Lebap": "TM-L",
            "Mary": "TM-M"
        }
    },
    "Tunisia": {
        "countryCode": "TN",
        statesList: {
            "Beja": "TN-31",
            "Ben Arous": "TN-13",
            "Bizerte": "TN-23",
            "Gabes": "TN-81",
            "Gafsa": "TN-71",
            "Jendouba": "TN-32",
            "Kairouan": "TN-41",
            "Kasserine": "TN-42",
            "Kebili": "TN-73",
            "L'Ariana": "TN-12",
            "La Manouba": "TN-14",
            "Le Kef": "TN-33",
            "Mahdia": "TN-53",
            "Medenine": "TN-82",
            "Monastir": "TN-52",
            "Nabeul": "TN-21",
            "Sfax": "TN-61",
            "Sidi Bouzid": "TN-43",
            "Siliana": "TN-34",
            "Sousse": "TN-51",
            "Tataouine": "TN-83",
            "Tozeur": "TN-72",
            "Tunis": "TN-11",
            "Zaghouan": "TN-22"
        }
    },
    "Tonga": {
        "countryCode": "TO",
        statesList: {
            "'Eua": "TO-01",
            "Ha'apai": "TO-02",
            "Niuas": "TO-03",
            "Tongatapu": "TO-04",
            "Vava'u": "TO-05"
        }
    },
    "Turkey": {
        "countryCode": "TR",
        statesList: {
            "Adana": "TR-01",
            "Adiyaman": "TR-02",
            "Afyonkarahisar": "TR-03",
            "Agri": "TR-04",
            "Aksaray": "TR-68",
            "Amasya": "TR-05",
            "Ankara": "TR-06",
            "Antalya": "TR-07",
            "Ardahan": "TR-75",
            "Artvin": "TR-08",
            "Aydin": "TR-09",
            "Balikesir": "TR-10",
            "Bartin": "TR-74",
            "Batman": "TR-72",
            "Bayburt": "TR-69",
            "Bilecik": "TR-11",
            "Bingol": "TR-12",
            "Bitlis": "TR-13",
            "Bolu": "TR-14",
            "Burdur": "TR-15",
            "Bursa": "TR-16",
            "Canakkale": "TR-17",
            "Cankiri": "TR-18",
            "Corum": "TR-19",
            "Denizli": "TR-20",
            "Diyarbakir": "TR-21",
            "Duzce": "TR-81",
            "Edirne": "TR-22",
            "Elazig": "TR-23",
            "Erzincan": "TR-24",
            "Erzurum": "TR-25",
            "Eskisehir": "TR-26",
            "Gaziantep": "TR-27",
            "Giresun": "TR-28",
            "Gumushane": "TR-29",
            "Hakkari": "TR-30",
            "Hatay": "TR-31",
            "Igdir": "TR-76",
            "Isparta": "TR-32",
            "Istanbul": "TR-34",
            "Izmir": "TR-35",
            "Kahramanmaras": "TR-46",
            "Karabuk": "TR-78",
            "Karaman": "TR-70",
            "Kars": "TR-36",
            "Kastamonu": "TR-37",
            "Kayseri": "TR-38",
            "Kilis": "TR-79",
            "Kirikkale": "TR-71",
            "Kirklareli": "TR-39",
            "Kirsehir": "TR-40",
            "Kocaeli": "TR-41",
            "Konya": "TR-42",
            "Kutahya": "TR-43",
            "Malatya": "TR-44",
            "Manisa": "TR-45",
            "Mardin": "TR-47",
            "Mersin": "TR-33",
            "Mugla": "TR-48",
            "Mus": "TR-49",
            "Nevsehir": "TR-50",
            "Nigde": "TR-51",
            "Ordu": "TR-52",
            "Osmaniye": "TR-80",
            "Rize": "TR-53",
            "Sakarya": "TR-54",
            "Samsun": "TR-55",
            "Sanliurfa": "TR-63",
            "Siirt": "TR-56",
            "Sinop": "TR-57",
            "Sirnak": "TR-73",
            "Sivas": "TR-58",
            "Tekirdag": "TR-59",
            "Tokat": "TR-60",
            "Trabzon": "TR-61",
            "Tunceli": "TR-62",
            "Usak": "TR-64",
            "Van": "TR-65",
            "Yalova": "TR-77",
            "Yozgat": "TR-66",
            "Zonguldak": "TR-67"
        }
    },
    "Trinidad and Tobago": {
        "countryCode": "TT",
        statesList: {
            "Arima": "TT-ARI",
            "Chaguanas": "TT-CHA",
            "Couva-Tabaquite-Talparo": "TT-CTT",
            "Diego Martin": "TT-DMN",
            "Mayaro-Rio Claro": "TT-MRC",
            "Penal-Debe": "TT-PED",
            "Point Fortin": "TT-PTF",
            "Port of Spain": "TT-POS",
            "Princes Town": "TT-PRT",
            "San Fernando": "TT-SFO",
            "San Juan-Laventille": "TT-SJL",
            "Sangre Grande": "TT-SGE",
            "Siparia": "TT-SIP",
            "Tobago": "TT-TOB",
            "Tunapuna-Piarco": "TT-TUP"
        }
    },
    "Tuvalu": {
        "countryCode": "TV",
        statesList: {
            "Funafuti": "TV-FUN"
        }
    },
    "Taiwan (Province of China)": {
        "countryCode": "TW",
        statesList: {
            "Changhua": "TW-CHA",
            "Chiayi": "TW-CYQ",
            "Hsinchu": "TW-HSQ",
            "Hualien": "TW-HUA",
            "Kaohsiung": "TW-KHH",
            "Keelung": "TW-KEE",
            "Kinmen": "TW-KIN",
            "Lienchiang": "TW-LIE",
            "Miaoli": "TW-MIA",
            "Nantou": "TW-NAN",
            "New Taipei": "TW-NWT",
            "Penghu": "TW-PEN",
            "Pingtung": "TW-PIF",
            "Taichung": "TW-TXG",
            "Tainan": "TW-TNN",
            "Taipei": "TW-TPE",
            "Taitung": "TW-TTT",
            "Taoyuan": "TW-TAO",
            "Yilan": "TW-ILA",
            "Yunlin": "TW-YUN"
        }
    },
    "Tanzania": {
        "countryCode": "TZ",
        statesList: {
            "Arusha": "TZ-01",
            "Dar es Salaam": "TZ-02",
            "Dodoma": "TZ-03",
            "Geita": "TZ-27",
            "Iringa": "TZ-04",
            "Kagera": "TZ-05",
            "Kaskazini Pemba": "TZ-06",
            "Kaskazini Unguja": "TZ-07",
            "Katavi": "TZ-28",
            "Kigoma": "TZ-08",
            "Kilimanjaro": "TZ-09",
            "Kusini Pemba": "TZ-10",
            "Kusini Unguja": "TZ-11",
            "Lindi": "TZ-12",
            "Manyara": "TZ-26",
            "Mara": "TZ-13",
            "Mbeya": "TZ-14",
            "Mjini Magharibi": "TZ-15",
            "Morogoro": "TZ-16",
            "Mtwara": "TZ-17",
            "Mwanza": "TZ-18",
            "Njombe": "TZ-29",
            "Pwani": "TZ-19",
            "Rukwa": "TZ-20",
            "Ruvuma": "TZ-21",
            "Shinyanga": "TZ-22",
            "Simiyu": "TZ-30",
            "Singida": "TZ-23",
            "Songwe": "TZ-31",
            "Tabora": "TZ-24",
            "Tanga": "TZ-25"
        }
    },
    "Ukraine": {
        "countryCode": "UA",
        statesList: {
            "Avtonomna Respublika Krym": "UA-43",
            "Cherkaska oblast": "UA-71",
            "Chernihivska oblast": "UA-74",
            "Chernivetska oblast": "UA-77",
            "Dnipropetrovska oblast": "UA-12",
            "Donetska oblast": "UA-14",
            "Ivano-Frankivska oblast": "UA-26",
            "Kharkivska oblast": "UA-63",
            "Khersonska oblast": "UA-65",
            "Khmelnytska oblast": "UA-68",
            "Kirovohradska oblast": "UA-35",
            "Kyiv": "UA-30",
            "Kyivska oblast": "UA-32",
            "Luhanska oblast": "UA-09",
            "Lvivska oblast": "UA-46",
            "Mykolaivska oblast": "UA-48",
            "Odeska oblast": "UA-51",
            "Poltavska oblast": "UA-53",
            "Rivnenska oblast": "UA-56",
            "Sevastopol": "UA-40",
            "Sumska oblast": "UA-59",
            "Ternopilska oblast": "UA-61",
            "Vinnytska oblast": "UA-05",
            "Volynska oblast": "UA-07",
            "Zakarpatska oblast": "UA-21",
            "Zaporizka oblast": "UA-23",
            "Zhytomyrska oblast": "UA-18"
        }
    },
    "Uganda": {
        "countryCode": "UG",
        statesList: {
            "Abim": "UG-314",
            "Adjumani": "UG-301",
            "Agago": "UG-322",
            "Alebtong": "UG-323",
            "Apac": "UG-302",
            "Arua": "UG-303",
            "Bududa": "UG-218",
            "Bugiri": "UG-201",
            "Buhweju": "UG-420",
            "Buikwe": "UG-117",
            "Bukedea": "UG-219",
            "Bukomansibi": "UG-118",
            "Bulambuli": "UG-225",
            "Bundibugyo": "UG-401",
            "Bushenyi": "UG-402",
            "Busia": "UG-202",
            "Buvuma": "UG-120",
            "Buyende": "UG-226",
            "Gomba": "UG-121",
            "Gulu": "UG-304",
            "Hoima": "UG-403",
            "Ibanda": "UG-417",
            "Iganga": "UG-203",
            "Isingiro": "UG-418",
            "Jinja": "UG-204",
            "Kaabong": "UG-318",
            "Kabale": "UG-404",
            "Kabarole": "UG-405",
            "Kaberamaido": "UG-213",
            "Kalangala": "UG-101",
            "Kaliro": "UG-222",
            "Kalungu": "UG-122",
            "Kampala": "UG-102",
            "Kamuli": "UG-205",
            "Kamwenge": "UG-413",
            "Kanungu": "UG-414",
            "Kapchorwa": "UG-206",
            "Kasese": "UG-406",
            "Katakwi": "UG-207",
            "Kayunga": "UG-112",
            "Kibaale": "UG-407",
            "Kiboga": "UG-103",
            "Kibuku": "UG-227",
            "Kiruhura": "UG-419",
            "Kiryandongo": "UG-421",
            "Kisoro": "UG-408",
            "Kitgum": "UG-305",
            "Koboko": "UG-319",
            "Kole": "UG-325",
            "Kumi": "UG-208",
            "Kween": "UG-228",
            "Kyankwanzi": "UG-123",
            "Kyegegwa": "UG-422",
            "Kyenjojo": "UG-415",
            "Lamwo": "UG-326",
            "Lira": "UG-307",
            "Luuka": "UG-229",
            "Luwero": "UG-104",
            "Lwengo": "UG-124",
            "Lyantonde": "UG-114",
            "Manafwa": "UG-223",
            "Maracha": "UG-320",
            "Masaka": "UG-105",
            "Masindi": "UG-409",
            "Mayuge": "UG-214",
            "Mbale": "UG-209",
            "Mbarara": "UG-410",
            "Mitooma": "UG-423",
            "Mityana": "UG-115",
            "Moroto": "UG-308",
            "Moyo": "UG-309",
            "Mpigi": "UG-106",
            "Mubende": "UG-107",
            "Mukono": "UG-108",
            "Nakapiripirit": "UG-311",
            "Nakaseke": "UG-116",
            "Nakasongola": "UG-109",
            "Namayingo": "UG-230",
            "Namutumba": "UG-224",
            "Napak": "UG-327",
            "Nebbi": "UG-310",
            "Ntoroko": "UG-424",
            "Ntungamo": "UG-411",
            "Nwoya": "UG-328",
            "Oyam": "UG-321",
            "Pader": "UG-312",
            "Rakai": "UG-110",
            "Rubirizi": "UG-425",
            "Rukungiri": "UG-412",
            "Sembabule": "UG-111",
            "Sheema": "UG-426",
            "Sironko": "UG-215",
            "Soroti": "UG-211",
            "Tororo": "UG-212",
            "Wakiso": "UG-113",
            "Yumbe": "UG-313",
            "Zombo": "UG-330"
        }
    },
    "United States Minor Outlying Islands": {
        "countryCode": "UM",
        statesList: {
            "Palmyra Atoll": "UM-95"
        }
    },
    "United States": { //changed to match with pardot, EPMS: United States of America
        "countryCode": "US",
        statesList: {
            "Alabama": "US-AL",
            "Alaska": "US-AK",
            "Arizona": "US-AZ",
            "Arkansas": "US-AR",
            "California": "US-CA",
            "Colorado": "US-CO",
            "Connecticut": "US-CT",
            "Delaware": "US-DE",
            "District of Columbia": "US-DC",
            "Florida": "US-FL",
            "Georgia": "US-GA",
            "Hawaii": "US-HI",
            "Idaho": "US-ID",
            "Illinois": "US-IL",
            "Indiana": "US-IN",
            "Iowa": "US-IA",
            "Kansas": "US-KS",
            "Kentucky": "US-KY",
            "Louisiana": "US-LA",
            "Maine": "US-ME",
            "Maryland": "US-MD",
            "Massachusetts": "US-MA",
            "Michigan": "US-MI",
            "Minnesota": "US-MN",
            "Mississippi": "US-MS",
            "Missouri": "US-MO",
            "Montana": "US-MT",
            "Nebraska": "US-NE",
            "Nevada": "US-NV",
            "New Hampshire": "US-NH",
            "New Jersey": "US-NJ",
            "New Mexico": "US-NM",
            "New York": "US-NY",
            "North Carolina": "US-NC",
            "North Dakota": "US-ND",
            "Ohio": "US-OH",
            "Oklahoma": "US-OK",
            "Oregon": "US-OR",
            "Pennsylvania": "US-PA",
            "Rhode Island": "US-RI",
            "South Carolina": "US-SC",
            "South Dakota": "US-SD",
            "Tennessee": "US-TN",
            "Texas": "US-TX",
            "Utah": "US-UT",
            "Vermont": "US-VT",
            "Virginia": "US-VA",
            "Washington": "US-WA",
            "West Virginia": "US-WV",
            "Wisconsin": "US-WI",
            "Wyoming": "US-WY",
            //not in EPMS: American Samoa, Amazon, Northern Mariana Isl, Puerto Rico, Guam, American Virgin Islands

        }
    },
    "Uruguay": {
        "countryCode": "UY",
        statesList: {
            "Artigas": "UY-AR",
            "Canelones": "UY-CA",
            "Cerro Largo": "UY-CL",
            "Colonia": "UY-CO",
            "Durazno": "UY-DU",
            "Flores": "UY-FS",
            "Florida": "UY-FD",
            "Lavalleja": "UY-LA",
            "Maldonado": "UY-MA",
            "Montevideo": "UY-MO",
            "Paysandu": "UY-PA",
            "Rio Negro": "UY-RN",
            "Rivera": "UY-RV",
            "Rocha": "UY-RO",
            "Salto": "UY-SA",
            "San Jose": "UY-SJ",
            "Soriano": "UY-SO",
            "Tacuarembo": "UY-TA",
            "Treinta y Tres": "UY-TT"
        }
    },
    "Uzbekistan": {
        "countryCode": "UZ",
        statesList: {
            "Andijon": "UZ-AN",
            "Buxoro": "UZ-BU",
            "Farg'ona": "UZ-FA",
            "Jizzax": "UZ-JI",
            "Namangan": "UZ-NG",
            "Navoiy": "UZ-NW",
            "Qashqadaryo": "UZ-QA",
            "Qoraqalpog'iston Respublikasi": "UZ-QR",
            "Samarqand": "UZ-SA",
            "Sirdaryo": "UZ-SI",
            "Surxondaryo": "UZ-SU",
            "Toshkent": "UZ-TK",
            "Xorazm": "UZ-XO"
        }
    },
    "Holy See": {
        "countryCode": "VA",
        statesList: {}
    },
    "Saint Vincent and the Grenadines": {
        "countryCode": "VC",
        statesList: {
            "Charlotte": "VC-01",
            "Grenadines": "VC-06",
            "Saint George": "VC-04",
            "Saint Patrick": "VC-05"
        }
    },
    "Venezuela (Bolivarian Republic of)": {
        "countryCode": "VE",
        statesList: {
            "Amazonas": "VE-Z",
            "Anzoategui": "VE-B",
            "Apure": "VE-C",
            "Aragua": "VE-D",
            "Barinas": "VE-E",
            "Bolivar": "VE-F",
            "Carabobo": "VE-G",
            "Cojedes": "VE-H",
            "Delta Amacuro": "VE-Y",
            "Dependencias Federales": "VE-W",
            "Distrito Capital": "VE-A",
            "Falcon": "VE-I",
            "Guarico": "VE-J",
            "La Guaira": "VE-X",
            "Lara": "VE-K",
            "Merida": "VE-L",
            "Miranda": "VE-M",
            "Monagas": "VE-N",
            "Nueva Esparta": "VE-O",
            "Portuguesa": "VE-P",
            "Sucre": "VE-R",
            "Tachira": "VE-S",
            "Trujillo": "VE-T",
            "Yaracuy": "VE-U",
            "Zulia": "VE-V"
        }
    },
    "Virgin Islands (British)": {
        "countryCode": "VG",
        statesList: {}
    },
    "Virgin Islands (U.S.)": {
        "countryCode": "VI",
        statesList: {}
    },
    "Viet Nam": {
        "countryCode": "VN",
        statesList: {
            "An Giang": "VN-44",
            "Ba Ria - Vung Tau": "VN-43",
            "Bac Giang": "VN-54",
            "Bac Kan": "VN-53",
            "Bac Lieu": "VN-55",
            "Bac Ninh": "VN-56",
            "Ben Tre": "VN-50",
            "Binh Dinh": "VN-31",
            "Binh Duong": "VN-57",
            "Binh Phuoc": "VN-58",
            "Binh Thuan": "VN-40",
            "Ca Mau": "VN-59",
            "Can Tho": "VN-CT",
            "Cao Bang": "VN-04",
            "Da Nang": "VN-DN",
            "Dak Lak": "VN-33",
            "Dak Nong": "VN-72",
            "Dien Bien": "VN-71",
            "Dong Nai": "VN-39",
            "Dong Thap": "VN-45",
            "Gia Lai": "VN-30",
            "Ha Giang": "VN-03",
            "Ha Nam": "VN-63",
            "Ha Noi": "VN-HN",
            "Ha Tinh": "VN-23",
            "Hai Duong": "VN-61",
            "Hai Phong": "VN-HP",
            "Hau Giang": "VN-73",
            "Ho Chi Minh": "VN-SG",
            "Hoa Binh": "VN-14",
            "Hung Yen": "VN-66",
            "Khanh Hoa": "VN-34",
            "Kien Giang": "VN-47",
            "Kon Tum": "VN-28",
            "Lai Chau": "VN-01",
            "Lam Dong": "VN-35",
            "Lang Son": "VN-09",
            "Lao Cai": "VN-02",
            "Long An": "VN-41",
            "Nam Dinh": "VN-67",
            "Nghe An": "VN-22",
            "Ninh Binh": "VN-18",
            "Ninh Thuan": "VN-36",
            "Phu Tho": "VN-68",
            "Phu Yen": "VN-32",
            "Quang Binh": "VN-24",
            "Quang Nam": "VN-27",
            "Quang Ngai": "VN-29",
            "Quang Ninh": "VN-13",
            "Quang Tri": "VN-25",
            "Soc Trang": "VN-52",
            "Son La": "VN-05",
            "Tay Ninh": "VN-37",
            "Thai Binh": "VN-20",
            "Thai Nguyen": "VN-69",
            "Thanh Hoa": "VN-21",
            "Thua Thien-Hue": "VN-26",
            "Tien Giang": "VN-46",
            "Tra Vinh": "VN-51",
            "Tuyen Quang": "VN-07",
            "Vinh Long": "VN-49",
            "Vinh Phuc": "VN-70",
            "Yen Bai": "VN-06"
        }
    },
    "Vanuatu": {
        "countryCode": "VU",
        statesList: {
            "Sanma": "VU-SAM",
            "Shefa": "VU-SEE",
            "Tafea": "VU-TAE",
            "Torba": "VU-TOB"
        }
    },
    "Wallis and Futuna": {
        "countryCode": "WF",
        statesList: {
            "Sigave": "WF-SG",
            "Uvea": "WF-UV"
        }
    },
    "Samoa": {
        "countryCode": "WS",
        statesList: {
            "Atua": "WS-AT",
            "Fa'asaleleaga": "WS-FA",
            "Gagaifomauga": "WS-GI",
            "Tuamasaga": "WS-TU"
        }
    },
    "Yemen": {
        "countryCode": "YE",
        statesList: {
            "'Adan": "YE-AD",
            "'Amran": "YE-AM",
            "Ad Dali'": "YE-DA",
            "Al Bayda'": "YE-BA",
            "Al Hudaydah": "YE-HU",
            "Al Jawf": "YE-JA",
            "Al Mahwit": "YE-MW",
            "Amanat al 'Asimah": "YE-SA",
            "Dhamar": "YE-DH",
            "Hadramawt": "YE-HD",
            "Hajjah": "YE-HJ",
            "Ibb": "YE-IB",
            "Lahij": "YE-LA",
            "Ma'rib": "YE-MA",
            "Raymah": "YE-RA",
            "Sa'dah": "YE-SD",
            "San'a'": "YE-SN",
            "Shabwah": "YE-SH",
            "Ta'izz": "YE-TA"
        }
    },
    "Mayotte": {
        "countryCode": "YT",
        statesList: {}
    },
    "South Africa": {
        "countryCode": "ZA",
        statesList: {
            "Eastern Cape": "ZA-EC",
            "Free State": "ZA-FS",
            "Gauteng": "ZA-GP",
            "Kwazulu-Natal": "ZA-KZN",
            "Limpopo": "ZA-LP",
            "Mpumalanga": "ZA-MP",
            "North-West": "ZA-NW",
            "Northern Cape": "ZA-NC",
            "Western Cape": "ZA-WC"
        }
    },
    "Zambia": {
        "countryCode": "ZM",
        statesList: {
            "Central": "ZM-02",
            "Copperbelt": "ZM-08",
            "Eastern": "ZM-03",
            "Luapula": "ZM-04",
            "Lusaka": "ZM-09",
            "Muchinga": "ZM-10",
            "North-Western": "ZM-06",
            "Northern": "ZM-05",
            "Southern": "ZM-07",
            "Western": "ZM-01"
        }
    },
    "Zimbabwe": {
        "countryCode": "ZW",
        statesList: {
            "Bulawayo": "ZW-BU",
            "Harare": "ZW-HA",
            "Manicaland": "ZW-MA",
            "Mashonaland Central": "ZW-MC",
            "Mashonaland East": "ZW-ME",
            "Mashonaland West": "ZW-MW",
            "Masvingo": "ZW-MV",
            "Matabeleland North": "ZW-MN",
            "Matabeleland South": "ZW-MS",
            "Midlands": "ZW-MI"
        }
    }
}
